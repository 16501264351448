export enum BookingMethod {
  Practice = "Practice",
  EmisAccess = "EMIS Access",
  PatientSchedule = "Patient Schedule",
  ExternalOrganisation = "External Organisation",
  EmisMobile = "EMIS Mobile",
  ChooseAndBook = "Choose and Book"
}

export enum ParcelStatusBookAppointmentAppOpen {
  LoadingSourceCode = "LOADING_SOURCE_CODE",
  Mounted = "MOUNTED",
  Mounting = "MOUNTING",
  Updating = "UPDATING",
  NotBootstrapped = "NOT_BOOTSTRAPPED",
  Bootstrapping = "BOOTSTRAPPING"
}
