import { JwtPayload } from "jwt-decode";
import { AppointmentStatus } from "../types/status";

export const LOCAL_PATIENT_ID = "13efb147-db94-445d-9b67-a205ae5812e1";

export const REASON_CHARACTERS_LIMIT = 150;
export const NOTE_CHARACTERS_LIMIT = 200;

export const MEDIUM_BREAKPOINT = 768;

export const statusMappings = {
  Booked: AppointmentStatus.SlotAvailable,
  "Did not attend": AppointmentStatus.DNA,
  "Visited and seen": AppointmentStatus.Visited,
  "Visited and not seen": AppointmentStatus.VisitedNotIn,
  "Call complete": AppointmentStatus.TelephoneComplete,
  "Start video call": AppointmentStatus.VideoStartCall,
  "Video call complete": AppointmentStatus.VideoComplete,
  "No answer - failed encounter": AppointmentStatus.TelephoneNotIn
};

export enum UserAuthorizations {
  UserManage = "users-user.manage",
  RoleManage = "users-role.manage",
  PersonMPIRead = "prsn-mpi.read",
  PersonMPIManage = "prsn-mpi.manage",
  CareRecordWrite = "clinical-cr.write",
  CareRecordRead = "clinical-cr.read",
  AppointmentsWrite = "appt-appt.write",
  AppointmentsRead = "appt-appt.read",
  MedsCreate = "meds-app.create",
  MedsRead = "meds-app.read",
  AgreementRead = "agmt-agmt.read",
  GpchtmlRead = "fhir-gpchtml.read",
  PdsRead = "fhir-pds.read",
  AuditRead = "audit-recd.read",
  docAppRead = "doc-app.read"
}

export interface DecodedToken {
  jwtPayload: JwtPayload;
  email: string;
  givenName: string;
  userERN: string;
  title: string;
  familyName: string;
  authorizations: string[];
  orgERN: string;
  orgName: string;
  roleERNs: string[];
  roleNames: string[];
  cdb: string;
  emisWebUserInRoleId: number;
  nonce: string;
  scp: string;
  azp: string;
  ver: string;
}
