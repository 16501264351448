import axiosInstance from "../utils/axiosInstance";

const AMEND_FILTER = "equal(reasonType,Booking)";
const CANCEL_FILTER = "equal(reasonType,Cancellation)";

const getReasons = async (isCancelReason: boolean = false) => {
  const response = await axiosInstance.get(`/appointments/reasons`, {
    params: {
      filter: isCancelReason ? CANCEL_FILTER : AMEND_FILTER
    }
  });

  return response?.data;
};

export { getReasons };
