import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-button";
import { Stack } from "@emisgroup/ui-layouts";
import Refresh from "~icons/ic/outline-refresh";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import styles from "./ErrorState.module.scss";
import useApplicationContext from "../../utils/hooks/useApplicationContext";

interface Props {
  isSeparateError?: boolean;
  errorOn?: string;
  isOpened?: boolean;
  setIsTryAgainClick: (clicked: boolean) => void;
}

const ErrorState = ({ isSeparateError, errorOn, isOpened, setIsTryAgainClick }: Props) => {
  const { t: translate } = useTranslation();
  const { onRefreshButtonClick } = useApplicationContext();

  const handleRefreshButton = () => {
    onRefreshButtonClick();
    setIsTryAgainClick(true);
  };
  return (
    <Stack className={isSeparateError ? styles.separateErrorContainer : styles.errorContainer} data-testid="error page">
      {isSeparateError ? (
        <div className={isOpened ? styles.separateContainerDetailsPanel : styles.separateContainer}>
          <div className={styles.separateErrorDescription} data-testid="separate-error-description">
            We can’t retrieve this patient’s {errorOn} appointments at the moment.
          </div>
          <Button className={styles.separateErrorButton} onClick={() => handleRefreshButton()} data-testid="tryAgain">
            <Refresh title="Try again" />
            <div className={styles.tryAgain}>Try again</div>
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.iconContainer}>
            <SystemProblem title="problem" size="x-large" color="#3B78BD" />
          </div>
          <div className={styles.containerTitle}>
            <h3 className={styles.title}>{translate("Appointments.SomethingWentWrongErrorTitle")}</h3>
            <p className={styles.description}>{translate("Appointments.SomethingWentWrongErrorMessage")}</p>
          </div>
          <div>
            <Button
              variant="filled"
              className={styles.button}
              onClick={() => handleRefreshButton()}
              data-testid="tryAgain"
            >
              <Refresh title="Try again" />
              Try again
            </Button>
          </div>
        </>
      )}
    </Stack>
  );
};

export default ErrorState;