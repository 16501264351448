import { getUserContext } from "@emisgroup/acp-utility-user-context";
import { jwtDecode } from "jwt-decode";
import { DecodedToken } from "../constants";
import { useEffect, useState } from "react";

const useUserPermisions = () => {
  const [authorizations, setAuthorizations] = useState<string[]>([]);
  const userContext = getUserContext();

  useEffect(() => {
    const getAuthorizations = async (): Promise<string[]> => {
      const token = await userContext.getAccessToken();

      const decoded = jwtDecode<DecodedToken>(token);

      setAuthorizations(decoded.authorizations);

      return decoded.authorizations;
    };

    getAuthorizations();
  }, []);

  return { authorizations };
};

export default useUserPermisions;
