import { useTranslation } from "@emisgroup/application-intl";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import { FC } from "react";
import HouseTwoTone from "~icons/ic/twotone-house";
import UserTwoTone from "~icons/ic/twotone-person";
import PhoneTwoTone from "~icons/ic/twotone-phone";
import styles from "./AppointmentIcon.module.scss";
import VideoCam from "~icons/ic/twotone-videocam";
import { AppointmentStatusType } from "../../types/status";

// import FeaturedIconSize after the Ui-team will export this interface
type FeaturedIconSize = "x-small" | "small" | "medium";

const WrittenIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#03646e">
    <path d="M40-120v-80h880v80H40Zm120-120q-33 0-56.5-23.5T80-320v-440q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v440q0 33-23.5 56.5T800-240H160Zm0-80h640v-440H160v440Zm0 0v-440 440Z" />
  </svg>
);
interface IAppointmentIconProps {
  appointmentMode: string;
  iconSize?: FeaturedIconSize;
}

const AppointmentIcon: FC<IAppointmentIconProps> = (props: IAppointmentIconProps) => {
  const { appointmentMode, iconSize = "small" } = props;
  const { t: translate } = useTranslation();

  switch (appointmentMode) {
    case AppointmentStatusType.FaceToFaceHomeVisit:
      return (
        <FeaturedIcon size={iconSize} color="--accent-3-99" data-testid="house-icon">
          <HouseTwoTone size="medium" title={translate("Appointments.FaceToFaceHomeVisit")} color="#552A64" />
        </FeaturedIcon>
      );
    case AppointmentStatusType.FaceToFaceSurgery:
      return (
        <FeaturedIcon size={iconSize} color="--accent-1-99" data-testid="user-icon">
          <UserTwoTone size="medium" title={translate("Appointments.FaceToFaceSurgery")} color="#415900" />
        </FeaturedIcon>
      );
    case AppointmentStatusType.TelephoneAudio:
      return (
        <FeaturedIcon size={iconSize} color="--accent-2-99" data-testid="phone-icon">
          <PhoneTwoTone size="medium" title={translate("Appointments.TelephoneAudio")} color="#03646E" />
        </FeaturedIcon>
      );
    case AppointmentStatusType.VideoWithAudio:
      // need to wait for video icon
      return (
        <FeaturedIcon size={iconSize} color="--accent-2-99" data-testid="video-icon">
          <VideoCam size="medium" title={translate("Appointments.VideoWithAudio")} color="#03646E" />
        </FeaturedIcon>
      );
    case AppointmentStatusType.Written:
      return (
        <FeaturedIcon size={iconSize} color="--accent-2-99" data-testid="written-icon">
          <div title={translate("Appointments.WrittenIncludingOnline")} className={styles.computerIc}>
            {WrittenIcon}
          </div>
        </FeaturedIcon>
      );
    default:
      return <div data-testid="wrong-appointment-mode"></div>;
  }
};

export default AppointmentIcon;
