import { Button } from "@emisgroup/ui-button";
import style from "./RefreshButton.module.scss";
import useApplicationContext from "../../../utils/hooks/useApplicationContext";
import { useTranslation } from "@emisgroup/application-intl";
import Refresh from "~icons/ic/outline-refresh";
import { FC } from "react";

interface RefreshButtonProps {
  isPatientSelected: boolean;
  isBannerLoaded: boolean;
  isMobileScreen: boolean;
  setIsRefreshButtonClicked: (clicked: boolean) => void;
}

const RefreshButton: FC<RefreshButtonProps> = ({
  isPatientSelected,
  isBannerLoaded,
  isMobileScreen,
  setIsRefreshButtonClicked
}) => {
  const { t: translate } = useTranslation();
  const { onRefreshButtonClick } = useApplicationContext();

  const handleRefreshButton = () => {
    onRefreshButtonClick();
    setIsRefreshButtonClicked(true);
  };

  return (
    <Button
      borderless={true}
      className={style.refreshButton}
      onClick={() => handleRefreshButton()}
      data-testid="refresh-button"
      title={translate("Appointments.RefreshBtnTitle")}
      tpActionName="RefreshAppointmentstData"
      disabled={!isPatientSelected || isBannerLoaded}
    >
      {!isMobileScreen && <Refresh className={style.refreshIcon} title="refresh" />}
      {translate("Appointments.RefreshBtn")}
    </Button>
  );
};

export default RefreshButton;
