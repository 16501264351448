// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZdBNUgFHhRG6oWgy_i2i{display:flex;align-items:center}.XKsi6OzQCxfNpNo7ifp6{margin-left:var(--spacing-small);width:100%}.uROPf0jfUg1Uk3y9MeOT{margin-left:var(--spacing-small);width:100%;min-width:165px;text-align:left}.IYvEYUlUnaxYRvqy0L6w{border:2px solid var(--white);border-radius:var(--border-radius-circle);min-width:32px;min-height:32px;text-align:left}@media screen and (min-width: 768px){.uROPf0jfUg1Uk3y9MeOT{min-width:auto}}`, "",{"version":3,"sources":["webpack://./src/components/common/UserDetails/UserDetails.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,gCAAA,CACA,UAAA,CAGF,sBACE,gCAAA,CACA,UAAA,CACA,eAAA,CACA,eAAA,CAGF,sBACE,6BAAA,CACA,yCAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CAGF,qCACE,sBACE,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clinicianDetails": `ZdBNUgFHhRG6oWgy_i2i`,
	"clinicianName": `XKsi6OzQCxfNpNo7ifp6`,
	"clinicianNameSmall": `uROPf0jfUg1Uk3y9MeOT`,
	"avatarSmall": `IYvEYUlUnaxYRvqy0L6w`
};
export default ___CSS_LOADER_EXPORT___;
