import { useState } from "react";
import { AppointmentResource } from "../../types/appointments";

interface AppointmentsHandlersProps {
  pastAppts: AppointmentResource[];
  upcomingAppts: AppointmentResource[];
}

const useDetailsPanelHandlers = ({ pastAppts, upcomingAppts }: AppointmentsHandlersProps) => {
  const [isOpenDetailsPanel, setIsOpenDetailsPanel] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>(undefined);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPastAppointment, setIsPastAppointment] = useState(false);

  const handleOpenDetailsPanel = (open: boolean, appointmentId: string, index: number, isPastAppointment: boolean) => {
    setIsOpenDetailsPanel(open);
    setSelectedAppointmentId(appointmentId);
    setSelectedIndex(index);
    setIsPastAppointment(isPastAppointment);
  };

  const handleGoToNextItem = () => {
    const appointments = isPastAppointment ? pastAppts : upcomingAppts;
    setSelectedAppointmentId(appointments[selectedIndex + 1]?.id);
    setSelectedIndex(selectedIndex + 1);
  };

  const handleGoToPreviousItem = () => {
    const appointments = isPastAppointment ? pastAppts : upcomingAppts;
    setSelectedAppointmentId(appointments[selectedIndex - 1]?.id);
    setSelectedIndex(selectedIndex - 1);
  };

  const handleCloseDetailsPanel = (isPanelOpen: boolean) => {
    setIsOpenDetailsPanel(isPanelOpen);
  };

  return {
    isOpenDetailsPanel,
    selectedAppointmentId,
    selectedIndex,
    isPastAppointment,
    handleOpenDetailsPanel,
    handleGoToNextItem,
    handleGoToPreviousItem,
    handleCloseDetailsPanel,
    setSelectedIndex
  };
};

export default useDetailsPanelHandlers;
