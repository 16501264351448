// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EGJhdX5Xa5shcUQ7xDaa{background-color:#f7f7f7;display:flex;text-align:center;align-items:center;height:calc(100vh - var(--navapp-height, 0px));justify-content:center}.gqAVRzn9IPsHmR4d5Cjm{font-size:2rem;font-weight:700;margin:20px}.g4L1QlDmYfoxknr6jTzT{font-size:.875rem;margin:20px}`, "",{"version":3,"sources":["webpack://./src/ErrorPage.module.scss","webpack://./node_modules/@emisgroup/ui-kit/build/dist/design-tokens/scss/_design-tokens.scss"],"names":[],"mappings":"AAEA,sBACE,wBCoCwB,CDnCxB,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,8CAAA,CACA,sBAAA,CAGF,sBACE,cCmEmB,CDlEnB,eCqDsB,CDpDtB,WAAA,CAGF,sBACE,iBC+DkB,CD9DlB,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `EGJhdX5Xa5shcUQ7xDaa`,
	"errorheader": `gqAVRzn9IPsHmR4d5Cjm`,
	"errorinstructions": `g4L1QlDmYfoxknr6jTzT`
};
export default ___CSS_LOADER_EXPORT___;
