export enum RelationshipType {
  CLINICAL_USER = "clinical-user",
  JOB_CATEGORY = "job-category",
  LOCATION = "location",
  LOCATION_TYPE = "location-type",
  ORGANISATION = "organisation",
  SLOT_TYPE = "slot-type",
  ERN_EMIS_TERM_CODE = "ern:emis:term:code",
  PATIENTS = "patients",
  REASONS = "reasons",
  STATUSES = "statuses"
}

export enum AppointmentRelationshipNames {
  bookedByUser = "bookedByUser",
  clinicians = "clinicians",
  dnaReason = "dnaReason",
  lastUpdatedByUser = "lastUpdatedByUser",
  locations = "locations",
  organisation = "organisation",
  patient = "patient",
  slotType = "slotType",
  statuses = "statuses",
  reasons = "reasons"
}
