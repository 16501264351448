import { useTranslation } from "@emisgroup/application-intl";
import { Switcher } from "@emisgroup/ui-layouts";

import { Skeleton } from "@emisgroup/ui-skeleton";
import classNames from "classnames";
import { AppointmentIncludedRelationships, AppointmentResource } from "../../../types/appointments";
import { getDateFormat, getTimeFormat } from "../../../utils/dateFormatter";
import useMobileView from "../../../utils/hooks/useMobileView";
import AppointmentIcon from "../../AppointmentIcon/AppointmentIcon";
import UserDetails from "../UserDetails/UserDetails";
import styles from "./PatientDetails.module.scss";

interface Props {
  children?: React.ReactNode;
  appointmentId: string;
  appointmentDetails: AppointmentResource;
  filteredDetails: AppointmentIncludedRelationships;
  isSkeletonLoading: boolean;
}

const SCREEN_WIDTH = 576;

const PatientDetails = ({ children, appointmentId, appointmentDetails, filteredDetails, isSkeletonLoading }: Props) => {
  const { t: translate } = useTranslation();

  const { isMobileView } = useMobileView(SCREEN_WIDTH);

  const renderDataOrSkeleton = (data: any, isMediumSize?: boolean) => {
    if (isSkeletonLoading) {
      return (
        <Skeleton.Item
          className={isMediumSize ? styles["skeletonMediumSize"] : styles["skeleton"]}
          data-testid="skeleton"
        />
      );
    } else {
      return <>{data}</>;
    }
  };

  return (
    <div className={styles["patient-details-grid"]}>
      <Switcher gap="0.5rem" threshold="var(--threshold-small)" className={styles["patient-details-grid-row"]}>
        <div
          className={classNames([
            styles["patient-details-grid-row__label"],
            {
              [styles["patient-details-grid-row__label--desktop"]]: !isMobileView,
              [styles["patient-details-grid-row__label--mobile"]]: isMobileView
            }
          ])}
        >
          {translate("Appointments.Patient")}
        </div>
        <div className={styles["patient-details-grid-row__patient-detail"]}>
          {renderDataOrSkeleton(filteredDetails?.patient.meta?.displayName)}
        </div>
      </Switcher>
      <Switcher gap="0.5rem" threshold="var(--threshold-small)" className={styles["patient-details-grid-row"]}>
        <div
          className={classNames([
            styles["patient-details-grid-row__label"],
            {
              [styles["patient-details-grid-row__label--desktop"]]: !isMobileView,
              [styles["patient-details-grid-row__label--mobile"]]: isMobileView
            }
          ])}
        >
          {translate("Appointments.ModeOfAppointment")}
        </div>
        <div className={styles["patient-details-grid-row__patient-detail"]}>
          <div className={styles["patient-details-grid-row__patient-detail__mode"]}>
            {isSkeletonLoading ? (
              <Skeleton.Item className={styles["skeleton"]} />
            ) : (
              filteredDetails?.slotTypes
                .filter((slotType) => slotType.appointmentId === appointmentId)
                .map((item) => (
                  <div key={item.appointmentId} className={styles["amend-grid-row__patient-detail__mode"]}>
                    <AppointmentIcon appointmentMode={item.attributes?.statusType} iconSize="x-small" />
                    <div data-testid="amend-appointment-status-type">{item.attributes?.statusType}</div>
                  </div>
                ))
            )}
          </div>
        </div>
      </Switcher>
      <Switcher gap="0.5rem" threshold="var(--threshold-small)" className={styles["patient-details-grid-row"]}>
        <div
          className={classNames([
            styles["patient-details-grid-row__label"],
            {
              [styles["patient-details-grid-row__label--desktop"]]: !isMobileView,
              [styles["patient-details-grid-row__label--mobile"]]: isMobileView
            }
          ])}
        >
          {translate("Appointments.DateAndTime")}
        </div>
        <div className={styles["patient-details-grid-row__patient-detail"]}>
          {isSkeletonLoading ? (
            <Skeleton.Item className={styles["skeleton"]} />
          ) : (
            <>
              <span
                data-testid="amend-appointment-date"
                className={styles["patient-details-grid-row__patient-detail__date-value"]}
              >
                {getDateFormat(appointmentDetails?.attributes?.startDateTime)}
              </span>
              <span data-testid="amend-appointment-date">
                {getTimeFormat(appointmentDetails?.attributes?.startDateTime)}
              </span>
            </>
          )}
        </div>
      </Switcher>
      <Switcher
        key={appointmentDetails?.id}
        gap="0.5rem"
        threshold="var(--threshold-small)"
        className={styles["patient-details-grid-row"]}
      >
        <div
          className={classNames([
            styles["patient-details-grid-row__label"],
            {
              [styles["patient-details-grid-row__label--desktop"]]: !isMobileView,
              [styles["patient-details-grid-row__label--mobile"]]: isMobileView
            }
          ])}
        >
          {translate("Appointments.HealthCareProfessional")}
        </div>
        <div key={appointmentDetails?.id} className={styles["patient-details-grid-row__patient-detail"]}>
          {isSkeletonLoading ? (
            <>
              <div className={styles.skeletonContainer}>
                <Skeleton.Item variant="circle" className={styles.skeletonCircle} />
                <Skeleton.Item className={styles.skeletonMediumSize} />
              </div>
              <Skeleton.Item className={styles["skeletonHealthcare"]} />
            </>
          ) : (
            filteredDetails?.clinicians.map((clinician) => (
              <div key={clinician.id} className={styles["amend-grid-row__patient-detail__clinicians"]}>
                <UserDetails user={clinician} color={clinician.meta?.displayName} isSmall={false} />
                <div className={styles["patient-details-grid-row__patient-detail__job-category-container"]}>
                  <span className={styles["patient-details-grid-row__patient-detail__job-category"]}>
                    {translate("Appointments.JobCategory")}:
                  </span>
                  {filteredDetails.jobCategory
                    .filter((jobCategory) => jobCategory.clinicianId === clinician.id)
                    .map((category) => (
                      <span
                        key={category.id}
                        data-testid="amend-appointment-clinician-job-category"
                        className={styles["amend-grid-row__patient-detail__job-description"]}
                      >
                        {category.attributes.description}
                      </span>
                    ))}
                </div>
              </div>
            ))
          )}
        </div>
      </Switcher>
      <Switcher gap="0.5rem" threshold="var(--threshold-small)" className={styles["patient-details-grid-row"]}>
        <div
          className={classNames([
            styles["patient-details-grid-row__label"],
            {
              [styles["patient-details-grid-row__label--desktop"]]: !isMobileView,
              [styles["patient-details-grid-row__label--mobile"]]: isMobileView
            }
          ])}
        >
          {translate("Appointments.Location")}
        </div>
        <div className={styles["patient-details-grid-row__patient-detail"]}>
          {renderDataOrSkeleton(filteredDetails?.organisation.attributes?.name, true)}
          <div className={styles["patient-details-grid-row__patient-detail__branch"]}>
            {!isSkeletonLoading && <span>{translate("Appointments.Branch")}:</span>}
            <span data-testid="amend-appointment-location">
              {renderDataOrSkeleton(filteredDetails?.organisation.attributes?.name)}
            </span>
          </div>
        </div>
      </Switcher>
      {children}
    </div>
  );
};

export default PatientDetails;
