/**
 * ! THIS FILE IS MANAGED BY THE MENDELEEV TEAM.
 * ! IF UPDATES ARE REQUIRED PLEASE SPEAK TO THE TEAM FIRST (https://emishealth.slack.com/archives/C0105EZHBCL)
 * ! OR RAISE AN ISSUE IN THE CREATE-EMISX-APP REPO (https://github.com/emisgroup/create-emisx-app/issues)
 */

import React, { useEffect, useState } from "react";
import { IntlProvider } from "./IntlProvider";
import App from "./App";
import { ILifeCycleProps } from "./Types";
import { getUserContext } from "@emisgroup/acp-utility-user-context";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import "@emisgroup/ui-styles/dist/base.css";

/**
 * Entry point for the application.
 *
 * @param props Lifecycle props. These can be used to get access to the base path for routing and the environment of the host e.g. dev, int, prd etc.
 * @returns Main application element
 */
const Root = (props: ILifeCycleProps): React.JSX.Element => {
  /*
     This section can be used to setup the application and clear it down when it is mounted into the frame
   */

  const { path } = props;
  const [authHandled, setAuthHandled] = useState(path !== undefined);

  const onApplicationMount = (): void => {
    // Setup code here

    // Set the language.
    // This is to bypass the need for a hard coded HtmlWebpackPlugin
    // template.
    // It also means we can derive this from elsewhere in the future.
    document.documentElement.lang = "en";

    if (authHandled) return;

    const userContext = getUserContext();

    userContext
      .handleRedirectPromise()
      .then(() => {
        if (userContext.isAuthenticated) {
          setAuthHandled(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onApplicationUnMount = (): void => {
    // Teardown code here
  };

  useEffect(() => {
    onApplicationMount();

    return (): void => {
      onApplicationUnMount();
    };
  });

  if (!authHandled) {
    return (
      <div data-testid="app-loading-progress-spinner">
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <IntlProvider>
      <App {...props} />
    </IntlProvider>
  );
};

export default Root;
