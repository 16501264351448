import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-button";
import { classNames } from "@emisgroup/ui-core";
import { Notification, NotificationContainer } from "@emisgroup/ui-notification";
import { FC, useEffect, useState } from "react";
import InfoConfirm from "~icons/ic/baseline-check-circle";
import Close from "~icons/ic/baseline-close";
import ErrorCircle from "~icons/ic/outline-error";
import style from "./Confirmation.module.scss";

interface ConfirmationProps {
  confirmationType: string;
  isConfirmationOpen: boolean;
  isConfirmationSuccess?: boolean;
  isStatusUpdated?: boolean;
  undoAppointmentId: string;
  handleClickUndoButton: (isUndo: boolean, undoAppointmentId: string) => void;
  closeConfirmation: (isClose: boolean) => void;
  showConfirmationWithoutAction: boolean;
}

const Confirmation: FC<ConfirmationProps> = ({
  confirmationType,
  isConfirmationOpen,
  isConfirmationSuccess,
  closeConfirmation,
  isStatusUpdated,
  handleClickUndoButton,
  undoAppointmentId,
  showConfirmationWithoutAction
}) => {
  const { t: translate } = useTranslation();
  const [notification, setNotification] = useState<JSX.Element>();

  const insertNotificationIntoStack = (notification: JSX.Element) => {
    setNotification(notification);
  };

  const handleUndo = () => {
    handleClickUndoButton(true, undoAppointmentId);
    closeConfirmation(false);
  };

  useEffect(() => {
    if (isConfirmationSuccess) {
      insertNotificationIntoStack(isStatusUpdated ? updateStatusNotification : appointmentUpdatedNotification);
    } else {
      insertNotificationIntoStack(appointmentErrorConfirmation);
    }
  }, [isConfirmationOpen, closeConfirmation]);

  const updateStatusNotification = (
    <Notification
      className={style.confirmationContainer}
      sensitivity="foreground"
      data-testid="confirmation-notification"
    >
      <div className={style.titleContainer}>
        <InfoConfirm color="var(--positive)" title="Success" />

        <Notification.Content className={style.statusTitle}>{confirmationType}</Notification.Content>
      </div>
      <div>
        {!showConfirmationWithoutAction && (
          <Notification.Action altText="confirmation" asChild={true} onClick={handleUndo}>
            <Button borderless={true}>{translate("Appointments.Undo")}</Button>
          </Notification.Action>
        )}
        <Notification.Close asChild={true}>
          <Button className={style.closeBtn} borderless={true}>
            <Close title="Close notification" />
          </Button>
        </Notification.Close>
      </div>
    </Notification>
  );

  const appointmentUpdatedNotification = (
    <Notification
      className={classNames([style.confirmationContainer, { [style.successConfirmation]: isConfirmationSuccess }])}
      sensitivity="foreground"
      data-testid="confirmation-notification"
    >
      <div className={style.titleContainer}>
        <InfoConfirm color="var(--positive)" title="Success" />
        <Notification.Content
          className={classNames([style.statusTitle, { [style.successTitle]: isConfirmationSuccess }])}
        >
          {translate("Appointments.Appointment") + " " + confirmationType}
        </Notification.Content>
      </div>
      <div>
        <Notification.Close asChild={true}>
          <Button borderless={true}>
            <Close className={style.closeBtn} title="Close notification" />
          </Button>
        </Notification.Close>
      </div>
    </Notification>
  );

  const appointmentErrorConfirmation = (
    <Notification
      className={classNames([style.confirmationContainer, { [style.errorConfirmation]: !isConfirmationSuccess }])}
      sensitivity="foreground"
    >
      <div className={style.titleContainer}>
        <ErrorCircle color="var(--negative)" title="Error" />
        <div className={style.titleAndContentContainer}>
          <Notification.Title
            className={classNames([style.statusTitle, { [style.errorTitle]: !isConfirmationSuccess }])}
          >
            {confirmationType}
          </Notification.Title>
          <Notification.Content>{translate("Appointments.TryAgain")}</Notification.Content>
        </div>
      </div>
      <div>
        <Notification.Close asChild={true}>
          <Button data-testid="close-btn" className={style.closeBtn} borderless={true}>
            <Close title="Close notification" />
          </Button>
        </Notification.Close>
      </div>
    </Notification>
  );

  return (
    <NotificationContainer>
      <>{notification}</>
    </NotificationContainer>
  );
};

export default Confirmation;
