import React from "react";
import { ILifeCycleProps } from "./Types";
import Appointments from "./components/appointments/Appointments";
import LaunchDarklyProvider from "./lib/launchDarkly/LaunchDarklyProvider";

const App: React.FC<ILifeCycleProps> = (props: ILifeCycleProps) => {
  return (
    <LaunchDarklyProvider>
      <Appointments />
    </LaunchDarklyProvider>
  );
};

export default App;
