import { useTranslation } from "@emisgroup/application-intl";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { classNames } from "@emisgroup/ui-core";
import { DetailPanel } from "@emisgroup/ui-detail-panel";
import { Skeleton } from "@emisgroup/ui-skeleton";
import { useCallback, useEffect, useState } from "react";
import Close from "~icons/ic/baseline-close";
import ChevronLeft from "~icons/ic/outline-chevron-left";
import ChevronRight from "~icons/ic/outline-chevron-right";
import { getAppointById } from "../../services/appointments";
import { getAppointmentRelationshipsData, renamingStatus } from "../../services/dataMapper";
import { AppointmentDetailsApiResponse, AppointmentResource } from "../../types/appointments";
import { AppointmentStatus, AppointmentStatusType } from "../../types/status";
import { useAppointmentUrgent } from "../../utils/appointment";
import { LOCAL_PATIENT_ID } from "../../utils/constants";
import { getDateFormat, getTimeFormat } from "../../utils/dateFormatter";
import useApplicationContext from "../../utils/hooks/useApplicationContext";
import UserDetails from "../common/UserDetails/UserDetails";
import styles from "./AppointmentsDetailsPanel.module.scss";

interface Props {
  appointmentIdUpdated: string;
  appointmentId?: string;
  isRightButtonDisabled?: boolean;
  isLeftButtonDisabled?: boolean;
  hideNavigationButtons?: boolean;
  statusUpdated?: AppointmentStatus;
  reasonUpdated?: string;
  notesUpdated?: string;
  closeDetailsPanel: (isPanelOpen: boolean) => void;
  goToNextAppointment?: () => void;
  goToPreviousAppointment?: () => void;
}
const SCREEN_WIDTH = 768;

const AppointmentsDetailsPanel = ({
  appointmentIdUpdated,
  appointmentId,
  isRightButtonDisabled,
  isLeftButtonDisabled,
  hideNavigationButtons,
  statusUpdated,
  reasonUpdated,
  notesUpdated,
  goToNextAppointment,
  goToPreviousAppointment,
  closeDetailsPanel
}: Props) => {
  const { t: translate } = useTranslation();
  const [isDNAStatus, setIsDNAStatus] = useState<boolean>(false);
  const [appointmentDetailsIncluded, setAppointmentDetailsIncluded] = useState<any[]>([]);
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentResource>(null);
  const [isCanceledStatus, setIsCanceledStatus] = useState<boolean>(false);
  const [statusToDisplay, setStatusToDisplay] = useState<string>();
  const [reasonToDisplay, setReasonToDisplay] = useState<string>();
  const [noteToDisplay, setNoteToDisplay] = useState<string>();
  const { personGuid } = useApplicationContext();
  const [isLoading, setIsLoading] = useState(false);

  const retrieveAppointmentDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const result: AppointmentDetailsApiResponse = await getAppointById(
        process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid,
        appointmentId
      );

      setAppointmentDetails(result?.data);
      setAppointmentDetailsIncluded(result?.included);
    } catch {
      console.error("Error in Appointment Details Api Call");
    } finally {
      setIsLoading(false);
    }
  }, [personGuid, appointmentId]);

  const renderDataOrSkeleton = (data: any, isDateSection = false) => {
    if (isLoading) {
      return (
        <Skeleton.Item
          className={isDateSection ? `${styles.skeleton} ${styles["skeleton-date-section"]}` : styles["skeleton"]}
        />
      );
    } else {
      return <>{data || <>&mdash;</>}</>;
    }
  };

  const filteredDetails = getAppointmentRelationshipsData(appointmentDetails, appointmentDetailsIncluded);

  const isRequiredStatus = (status: string) => {
    switch (status) {
      case "DNA":
        setIsDNAStatus(true);
        setIsCanceledStatus(false);
        break;
      case "Cancelled":
        setIsCanceledStatus(true);
        setIsDNAStatus(false);
        break;
      default:
        setIsCanceledStatus(false);
        setIsDNAStatus(false);
    }
  };

  const isUrgent = useAppointmentUrgent(appointmentDetails?.attributes.urgent);
  const face2Face = filteredDetails?.slotTypes.find((value) =>
    value.attributes?.statusType?.includes(AppointmentStatusType.FaceToFaceSurgery)
  );

  const updateStatusNoteReason = () => {
    let previousStatus: AppointmentStatus = appointmentDetails?.attributes.status;
    let previousNote: string = appointmentDetails?.attributes.notes;
    let previousReason: string = appointmentDetails?.attributes.reason;

    if (appointmentDetails?.id === appointmentIdUpdated) {
      previousStatus = statusUpdated;
      // if (notesUpdated) = you didn't modify reason/notes from amend screen and notesUpdated is an empty string from initialization
      if (notesUpdated) previousNote = notesUpdated;
      if (reasonUpdated) previousReason = reasonUpdated;
    }
    setStatusToDisplay(renamingStatus(previousStatus));
    setNoteToDisplay(previousNote);
    setReasonToDisplay(previousReason);
  };

  useEffect(() => {
    updateStatusNoteReason();
  }, [
    appointmentIdUpdated,
    statusUpdated,
    notesUpdated,
    reasonUpdated,
    appointmentDetails?.attributes.status,
    appointmentDetails?.attributes.reason,
    appointmentDetails?.attributes.notes
  ]);

  useEffect(() => {
    isRequiredStatus(appointmentDetails?.attributes.status);
  });

  useEffect(() => {
    retrieveAppointmentDetails();
  }, [appointmentId]);

  const isHideRoomSection = false;

  return (
    <div className={styles["details-panel-container"]}>
      <DetailPanel className={styles["details-panel"]} data-testid="appointments-details-panel">
        <DetailPanel.Header className={styles["details-header"]}>
          <div className={styles.detailsPanelTitle}> {translate("Appointments.DetailsTitle")}</div>
          <Button
            aria-label="Close"
            borderless={true}
            data-testid="detailPanel-close-button"
            type="button"
            variant="mono"
          >
            <Close title="close" onClick={() => closeDetailsPanel(false)} />
          </Button>
        </DetailPanel.Header>
        <DetailPanel.Content>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <div className={styles["generic-section-label"]} data-testid="appointments-details-panel-date-time-label">
                {translate("Appointments.DateAndTime")}
              </div>
              <div className={styles["date-time-value"]}>
                <span data-testid="formatted-date" className={styles["date-value"]}>
                  {renderDataOrSkeleton(getDateFormat(appointmentDetails?.attributes.startDateTime), true)}
                </span>
                {appointmentDetails?.meta.timed && <span data-testid="formatted-time">
                  {renderDataOrSkeleton(getTimeFormat(appointmentDetails?.attributes.startDateTime), true)}
                </span>}
              </div>
            </div>
            {appointmentDetails?.meta.timed && <div className={styles["generic-value"]}>
              <span className={styles["duration-urgent-section"]}>{translate("Appointments.Duration")}</span>
              <span data-testid="duration-property" className={styles["generic-value"]}>
                {renderDataOrSkeleton(
                  Math.round(appointmentDetails?.meta.duration) + " " + translate("Appointments.Minutes"),
                  true
                )}
              </span>
            </div>}
            <div className={styles["generic-value"]}>
              <span className={styles["duration-urgent-section"]}>{translate("Appointments.Urgent")}</span>
              <span data-testid="urgent-property" className={styles["generic-value"]}>
                {renderDataOrSkeleton(isUrgent, true)}
              </span>
            </div>
          </div>
          <div className={styles["details-section"]}>
            {isLoading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton.Item variant="circle" className={styles.skeletonCircle} />
                <Skeleton.Item className={styles.skeleton} />
              </div>
            ) : (
              filteredDetails?.clinicians.map((clinician) => (
                <div key={clinician.id} className={styles["helth-care-section"]}>
                  <div className={styles["lable-section"]}>
                    <div className={styles["generic-section-label"]}>
                      {translate("Appointments.HealthCareProfessional")}
                    </div>
                    <UserDetails user={clinician} color={clinician?.meta?.displayName} isSmall={false} />
                  </div>

                  <div className={styles["generic-value"]}>
                    <span className={styles["type-section"]}>{translate("Appointments.Type")}</span>
                    {filteredDetails?.jobCategory
                      .filter((jobCategory) => jobCategory.clinicianId === clinician.id)
                      .map((category) => (
                        <span
                          key={category.id}
                          data-testid="clinician-job-category-property"
                          className={classNames([
                            styles["type-value"],
                            {
                              [styles.multiplelinesTypeValue]: category?.attributes.description.length > 50
                            }
                          ])}
                        >
                          {renderDataOrSkeleton(category?.attributes.description)}
                        </span>
                      ))}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.Location")}</span>
              <div
                data-testid="location-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: filteredDetails.organisation.attributes?.name?.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(filteredDetails.organisation?.attributes?.name)}
              </div>
            </div>
            <div className={styles["generic-value"]}>
              <span className={styles["branch-room-source-section"]}>{translate("Appointments.Branch")}</span>
              <span
                data-testid="location-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: filteredDetails.organisation.attributes?.name?.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(filteredDetails.organisation?.attributes?.name)}
              </span>
            </div>
            {/* Room filed should be hidden for now  {filteredDetails?.organisation?.attributes?.name}*/}
            {isHideRoomSection && (
              <div>
                {face2Face && (
                  <>
                    <span className={styles["branch-room-source-section"]}>{translate("Appointments.Room")}</span>
                    <span data-testid="room-property" className={styles["type-value"]}></span>
                  </>
                )}
              </div>
            )}
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.Reason")}</span>
              <div
                data-testid="reason-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: reasonToDisplay?.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(reasonToDisplay)}
              </div>
            </div>
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.Mode")}</span>
              <div data-testid="mode-property" className={styles["type-value"]}>
                {renderDataOrSkeleton(
                  filteredDetails?.slotTypes
                    .filter((type) => type.appointmentId === appointmentId)
                    .map((type) => type?.attributes?.statusType)
                )}
              </div>
            </div>
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.Note")}</span>
              <div
                data-testid="note-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: noteToDisplay?.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(noteToDisplay)}
              </div>
            </div>
          </div>
          <div className={styles["details-section"]}>
            {isLoading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton.Item className={styles.skeleton} />
              </div>
            ) : (
              <>
                <div className={styles["lable-section"]}>
                  <span className={styles["generic-section-label"]}>{translate("Appointments.Status")}</span>
                  <div data-testid="status-property" className={styles["type-value"]}>
                    {renderDataOrSkeleton(statusToDisplay)}
                  </div>
                </div>
                {isCanceledStatus && (
                  <>
                    <div className={styles["lable-section"]}>
                      <span className={styles["status-section"]}>{translate("Appointments.CancellationReason")}</span>
                      <span
                        data-testid="cancellation-reson-property"
                        className={classNames([
                          styles["type-value"],
                          {
                            [styles.multiplelinesTypeValue]:
                              appointmentDetails.attributes.cancellationReason?.length > 50
                          }
                        ])}
                      >
                        {appointmentDetails.attributes.cancellationReason}
                      </span>
                    </div>
                    <div>
                      <span className={styles["status-section"]}>{translate("Appointments.CancelledOn")}</span>
                      <span data-testid="canceled-date" className={styles["date-value"]}>
                        {getDateFormat(appointmentDetails.meta.cancellationDateTime)}
                      </span>
                      <span data-testid="canceled-time" className={styles["date-value"]}>
                        {getTimeFormat(appointmentDetails.meta.cancellationDateTime)}
                      </span>
                    </div>
                  </>
                )}
                {isDNAStatus && (
                  <div className={styles["lable-section"]}>
                    <span className={styles["status-dna-section"]}>{translate("Appointments.Reason")}</span>
                    <span
                      data-testid="dna-reson-property"
                      className={classNames([
                        styles["type-value"],
                        {
                          [styles.multiplelinesTypeValue]: filteredDetails?.dnaReason?.attributes.term.length > 50
                        }
                      ])}
                    >
                      {renderDataOrSkeleton(filteredDetails?.dnaReason?.attributes.term)}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.SlotType")}</span>
              <div data-testid="slot-type-property" className={styles["type-value"]}>
                {renderDataOrSkeleton(
                  filteredDetails?.slotTypes
                    .filter((type) => type.appointmentId === appointmentId)
                    .map((type) => type?.attributes?.description)
                )}
              </div>
            </div>
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.SessionType")}</span>
              <div
                data-testid="session-type-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: appointmentDetails?.attributes.sessionType.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(appointmentDetails?.attributes.sessionType)}
              </div>
            </div>
          </div>
          <div className={styles["details-section"]}>
            <div className={styles["lable-section"]}>
              <span className={styles["generic-section-label"]}>{translate("Appointments.BookingMethod")}</span>
              <div
                data-testid="booking-method-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: appointmentDetails?.attributes.bookingMethod?.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(appointmentDetails?.attributes.bookingMethod)}
              </div>
            </div>

            {/* Hide it based on: 417735 
            
            <div>
              <span className={styles["branch-room-source-section"]}>{translate("Appointments.Source")}</span>
              <span className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: noteToDisplay?.length > 50
                  }
                ])}>No source yet</span>
            </div> */}
          </div>
          {isLoading ? (
            <></>
          ) : (
            filteredDetails?.bookedByUser && (
              <div className={styles["details-section"]}>
                <div className={styles["lable-section"]}>
                  <span className={styles["generic-section-label"]}>{translate("Appointments.BookedBy")}</span>
                  <UserDetails
                    user={filteredDetails?.bookedByUser}
                    color={filteredDetails?.bookedByUser?.meta?.displayName}
                    isSmall={false}
                  />
                </div>
                <div>
                  <span className={styles["booked-organization-updated-section"]}>
                    {translate("Appointments.Role")}
                  </span>
                  <span className={styles["type-value"]}>
                    {
                      appointmentDetailsIncluded?.find(
                        (element) => element.id === filteredDetails?.bookedByUser?.relationships?.jobCategory.data?.id
                      )?.attributes.name
                    }
                  </span>
                </div>
                <div>
                  <span className={styles["booked-organization-updated-section"]}>
                    {translate("Appointments.Organisation")}
                  </span>
                  <span
                    data-testid="booked-user-organization-property"
                    className={classNames([
                      styles["type-value"],
                      {
                        [styles.multiplelinesTypeValue]: filteredDetails.organisation.attributes?.name?.length > 50
                      }
                    ])}
                  >
                    {filteredDetails.organisation?.attributes?.name}
                  </span>
                </div>
                <div className={styles["booked-on-upadate-on-section"]}>
                  <span className={styles["booked-organization-updated-section"]}>
                    {translate("Appointments.BookedOn")}
                  </span>
                  <div className={styles["generic-value"]}>
                    <span className={styles["date-value"]}>
                      {getDateFormat(appointmentDetails?.meta.bookedDateTime)}
                    </span>
                    <span className={styles["date-value"]}>
                      {getTimeFormat(appointmentDetails?.meta.bookedDateTime)}
                    </span>
                  </div>
                </div>
              </div>
            )
          )}
          <div className={styles["details-section"]}>
            {isLoading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton.Item variant="circle" className={styles.skeletonCircle} />
                <Skeleton.Item className={styles.skeleton} />
              </div>
            ) : (
              <div className={styles["lable-section"]}>
                <span className={styles["generic-section-label"]}>{translate("Appointments.LastUpdatedBy")}</span>

                <UserDetails
                  user={filteredDetails?.lastUpdatedByUser}
                  color={filteredDetails?.lastUpdatedByUser?.meta?.displayName}
                  isSmall={false}
                />
              </div>
            )}
            <div>
              <span className={styles["booked-organization-updated-section"]}>{translate("Appointments.Role")}</span>
              <span className={styles["type-value"]}>
                {renderDataOrSkeleton(
                  appointmentDetailsIncluded?.find(
                    (element) => element.id === filteredDetails.lastUpdatedByUser.relationships?.jobCategory.data?.id
                  )?.attributes.name
                )}
              </span>
            </div>
            <div>
              <span className={styles["booked-organization-updated-section"]}>
                {translate("Appointments.Organisation")}
              </span>
              <span
                data-testid="last-updated-user-organization-property"
                className={classNames([
                  styles["type-value"],
                  {
                    [styles.multiplelinesTypeValue]: filteredDetails.organisation.attributes?.name?.length > 50
                  }
                ])}
              >
                {renderDataOrSkeleton(filteredDetails.organisation?.attributes?.name)}
              </span>
            </div>
            <div className={styles["booked-on-upadate-on-section"]}>
              <span className={styles["booked-organization-updated-section"]}>
                {translate("Appointments.UpdateOn")}
              </span>
              <div className={styles["generic-value"]}>
                <span data-testid="last-updated-date" className={styles["date-value"]}>
                  {renderDataOrSkeleton(getDateFormat(appointmentDetails?.meta.lastUpdatedDateTime), true)}
                </span>
                <span data-testid="last-updated-time" className={styles["date-value"]}>
                  {renderDataOrSkeleton(getTimeFormat(appointmentDetails?.meta.lastUpdatedDateTime), true)}
                </span>
              </div>
            </div>
          </div>
        </DetailPanel.Content>
        {!hideNavigationButtons && (
          <DetailPanel.Footer className={styles["footer-container"]}>
            <ButtonGroup className={styles["navigation-button-group"]}>
              <Button
                aria-label="Chevron Left"
                data-testid="detailPanel-left-button"
                type="button"
                variant="mono"
                disabled={isLeftButtonDisabled}
                onClick={() => goToPreviousAppointment()}
              >
                <ChevronLeft title={"Left"} size="x-large" />
              </Button>
              <Button
                aria-label="Chevron Right"
                data-testid="detailPanel-right-button"
                type="button"
                variant="mono"
                disabled={isRightButtonDisabled}
                onClick={() => goToNextAppointment()}
              >
                <ChevronRight title={"Right"} size="x-large" />
              </Button>
            </ButtonGroup>
          </DetailPanel.Footer>
        )}
      </DetailPanel>
    </div>
  );
};

export default AppointmentsDetailsPanel;
