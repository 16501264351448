import { ParcelStatusBookAppointmentAppOpen } from "../../types/booking";

export const IsBookAppointmentAppOpen = (parcelStatus: string) => {
  return (
    parcelStatus === ParcelStatusBookAppointmentAppOpen.LoadingSourceCode ||
    parcelStatus === ParcelStatusBookAppointmentAppOpen.Mounted ||
    parcelStatus === ParcelStatusBookAppointmentAppOpen.Mounting ||
    parcelStatus === ParcelStatusBookAppointmentAppOpen.Updating ||
    parcelStatus === ParcelStatusBookAppointmentAppOpen.NotBootstrapped ||
    parcelStatus === ParcelStatusBookAppointmentAppOpen.Bootstrapping
  );
};
