import { useTranslation } from "@emisgroup/application-intl";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Content, Dialog, DialogInner, Header } from "@emisgroup/ui-dialog";
import { FormButtons, FormElement } from "@emisgroup/ui-form";
import { Textarea } from "@emisgroup/ui-input";
import { RadioButton, RadioButtonGroup } from "@emisgroup/ui-radio-button";
import { Skeleton } from "@emisgroup/ui-skeleton";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getAppointById, updateAppointment } from "../../services/appointments";
import { getAppointmentRelationshipsData, getStatusesList, renamingStatus } from "../../services/dataMapper";
import { getReasons } from "../../services/reasons";
import {
  AppointmentDetailsApiResponse,
  AppointmentIncludedRelationships,
  AppointmentResource,
  StatusResource
} from "../../types/appointments";
import { ErrorResponseCodes, ResponseStatus } from "../../types/axiosResponse";
import { AppointmentReasonApiResponse, ReasonResource } from "../../types/reasons";
import { LOCAL_PATIENT_ID, NOTE_CHARACTERS_LIMIT, statusMappings } from "../../utils/constants";
import { hasFieldOnlyAsciiCharacters } from "../../utils/fieldValidator";
import useApplicationContext from "../../utils/hooks/useApplicationContext";
import usePrevious from "../../utils/hooks/usePrevious";
import ErrorBanner from "../ErrorBanner/ErrorBanner";
import DialogForm from "../common/DialogForm/DialogForm";
import PatientDetails from "../common/PatientDetails/PatientDetails";
import SaveChangesDialog from "../common/SaveChangesDialog/SaveChangesDialog";
import styles from "./AmendDialog.module.scss";
import { getUpdateStatusPemissions } from "../../utils/updateStatusPermissions";

interface Props {
  appointmentId?: string;
  nextStatuses?: StatusResource[];
  showDialog?: boolean;
  hideCancelAppointmentOption?: boolean;
  closeAmendDialog?: () => void;
  updateAppointmentAttributes?: (appointmentId: string, reason: string, notes: string) => void;
  updateAmendStatus: (status: string, appointmentId: string) => void;
  showConfirmationMessage?: (
    isConfirmationOpen: boolean,
    confirmationText: string,
    setIsConfirmationSuccess?: boolean
  ) => void;
  openCancelAppointmentDialog: () => void;
}

const AmendDialog = ({
  appointmentId,
  nextStatuses,
  showDialog,
  hideCancelAppointmentOption,
  closeAmendDialog,
  updateAppointmentAttributes,
  updateAmendStatus,
  showConfirmationMessage,
  openCancelAppointmentDialog
}: Props) => {
  const { t: translate } = useTranslation();
  const [notes, setNotes] = useState("");
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentResource>(null);
  const [appointmentDetailsIncluded, setAppointmentDetailsIncluded] = useState<any[]>([]);
  const [appointmentReasons, setAppointmentReasons] = useState<ReasonResource[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [statuses, setStatuses] = useState<StatusResource[]>();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isReasonError, setIsReasonError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState<ReasonResource>();
  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);
  const [notOnlyAsciiInNoteError, setNotOnlyAsciiInNoteError] = useState<string | string[]>("");
  const [reasonError, setReasonError] = useState<string | string[]>("");

  const { personGuid } = useApplicationContext();

  const previousReasonValue = usePrevious(inputValue);
  const previousNoteValue = usePrevious(notes);
  const previousStatus = usePrevious(selectedStatus);

  const retrieveAppointmentDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const result: AppointmentDetailsApiResponse = await getAppointById(
        process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid,
        appointmentId
      );

      setAppointmentDetails(result?.data);
      setAppointmentDetailsIncluded(result?.included);
    } catch (error) {
      console.error(`Error is in AmendDialog: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [personGuid, appointmentId]);

  const retrieveAppointmentReasons = useCallback(async () => {
    try {
      const result: AppointmentReasonApiResponse = await getReasons();

      setAppointmentReasons(result?.data);
      setIsReasonError(false);
    } catch {
      setIsReasonError(true);
    }
  }, [personGuid, appointmentId]);

  const saveAppointment = async () => {
    if (reasonError) {
      return;
    }

    try {
      if (notes?.length > NOTE_CHARACTERS_LIMIT) return;

      const mappedSelectedStatus = statusMappings[selectedStatus] || selectedStatus;

      const result = await updateAppointment(
        process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid,
        appointmentId,
        inputValue,
        notes,
        mappedSelectedStatus
      );

      if (result.status == ResponseStatus.OK) {
        updateAppointmentAttributes(result.data.id, result.data.attributes.reason, result.data.attributes.notes);
        const mappedStatus = statusMappings[result.data.attributes.status] || result.data.attributes.status;
        updateAmendStatus(mappedStatus, appointmentId);
        showConfirmationMessage(true, translate("Appointments.Updated"), true);
      }

      setReasonError("");
      setIsError(false);
      closeAmendDialog();
    } catch (error) {
      setIsError(true);
      return ErrorResponseCodes.ERR_NETWORK;
    }
  };

  const filteredDetails: AppointmentIncludedRelationships = useMemo(() => {
    const filteredDetails = getAppointmentRelationshipsData(appointmentDetails, appointmentDetailsIncluded);

    return filteredDetails;
  }, [appointmentDetails, appointmentDetailsIncluded]);

  const currentStatus = useMemo(() => {
    return appointmentDetails?.attributes.status;
  }, [appointmentDetails]);

  const statusRelationships = useMemo(() => {
    const foundedSlotType = filteredDetails?.slotTypes.find((slot) => slot.appointmentId === appointmentId);
    if (!foundedSlotType?.id) return;

    const slotType = filteredDetails.slotTypes.find((slot) => slot.appointmentId === appointmentId);
    const newFilteredStatuses = getStatusesList(statuses, slotType.attributes.statusType, currentStatus);

    return newFilteredStatuses;
  }, [filteredDetails]);

  const appointmentStartDate = useMemo(() => appointmentDetails?.attributes.startDateTime, [appointmentDetails]);

  const hasUpdateStatusPermissions = getUpdateStatusPemissions(appointmentStartDate);

  const handleChangeStatus = useCallback((newStatus: string) => {
    setSelectedStatus(newStatus);
  }, []);

  const addCustomReasonToReasonsList = (newReason: string) => {
    const foundReason: ReasonResource = appointmentReasons?.find((r) => r.attributes.description === newReason);

    if (!foundReason && newReason)
      setAppointmentReasons((prevValue) => [
        ...prevValue,
        {
          id: uuidv4(),
          attributes: {
            description: newReason,
            reasonType: "Booking"
          },
          type: "reasons"
        }
      ]);
  };

  const onChangeReasonField = (reasonValue: string) => {
    setInputValue(reasonValue);

    const hasReasonOnlyAscii = hasFieldOnlyAsciiCharacters(inputValue);
    if (!inputValue || !hasReasonOnlyAscii) setReasonError("");
  };

  const onChangeNoteField = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
    setNotOnlyAsciiInNoteError("");
  };

  const onBlurAction = (fieldName: string) => {
    if (!inputValue) {
      setReasonError(`${translate("Appointments.ReasonReqiuredErrorMessage")}`);
    }

    const hasReasonOnlyAscii = hasFieldOnlyAsciiCharacters(inputValue);
    if (fieldName === "reason-picker" && !hasReasonOnlyAscii) {
      setReasonError(`${translate("Appointments.Reason")} ${translate("Appointments.NonAsciiMessage")}`);
    }

    const hasNotesOnlyAscii = hasFieldOnlyAsciiCharacters(notes);
    if (fieldName === "notes" && !hasNotesOnlyAscii) {
      setNotOnlyAsciiInNoteError(`${translate("Appointments.Note")} ${translate("Appointments.NonAsciiMessage")}`);
    }
  };

  const handleOpenUnsavedWarning = useCallback(() => {
    if (previousReasonValue && previousNoteValue && previousStatus) {
      if (previousReasonValue !== inputValue || previousNoteValue !== notes || previousStatus !== selectedStatus) {
        setShowSaveChangesDialog(true);
      }
    } else {
      closeAmendDialog();
      openCancelAppointmentDialog();
    }
  }, [inputValue, notes, selectedStatus]);

  const closeSaveChangesDialog = () => {
    setShowSaveChangesDialog(false);
  };

  const handleSaveCancelAppointment = useCallback(async () => {
    if (reasonError || notes?.length > NOTE_CHARACTERS_LIMIT || notOnlyAsciiInNoteError || isError) {
      closeSaveChangesDialog();
      return;
    }

    if (previousReasonValue && previousNoteValue && previousStatus) {
      if (previousReasonValue !== inputValue || previousNoteValue !== notes || previousStatus !== selectedStatus) {
        closeSaveChangesDialog();
        const response = await saveAppointment();

        if (response !== ErrorResponseCodes.ERR_NETWORK) {
          openCancelAppointmentDialog();
        }
      }
    } else {
      closeSaveChangesDialog();
      closeAmendDialog();
      openCancelAppointmentDialog();
    }
  }, [inputValue, notes, selectedStatus, reasonError, notOnlyAsciiInNoteError, isError]);

  useEffect(() => {
    setStatuses(nextStatuses);

    retrieveAppointmentDetails();
    retrieveAppointmentReasons();
  }, [appointmentId]);

  useEffect(() => {
    addCustomReasonToReasonsList(appointmentDetails?.attributes.reason);
    setNotes(appointmentDetails?.attributes.notes);
    setSelectedStatus(appointmentDetails?.attributes.status);

    const foundReason: ReasonResource = appointmentReasons?.find(
      (r) => r.attributes.description === appointmentDetails?.attributes.reason
    );

    if (foundReason) {
      setSelectedReason(foundReason);
      setInputValue(foundReason.attributes.description);
    }
  }, [appointmentReasons, appointmentDetails]);

  return (
    <>
      <Dialog onOpenChange={closeAmendDialog} open={showDialog} data-testid="amend-dialog">
        <DialogInner aria-label="form">
          <Header className={styles.header}>
            <div className={styles.headerTitle}>{translate("Appointments.AmendAppointment")}</div>
          </Header>
          {isError && (
            <Content className={styles.contentContainer}>
              <div className={styles.bannerError}>
                <ErrorBanner />
              </div>
            </Content>
          )}
          <Content style={{ padding: "unset" }} data-testid="amend-dialog-content">
            <PatientDetails
              appointmentId={appointmentId}
              appointmentDetails={appointmentDetails}
              filteredDetails={filteredDetails}
              isSkeletonLoading={isLoading}
            />
            <DialogForm
              comboboxLabel={translate("Appointments.Combobox")}
              comboboxPlaceholder={translate("Appointments.ReasonPlaceholder")}
              formLabel={translate("Appointments.Reason")}
              errorText={reasonError}
              reasonErrorMessage={translate("Appointments.ReasonError")}
              hasReasonError={isReasonError}
              isLoading={isLoading}
              reasons={appointmentReasons}
              selectedReason={selectedReason}
              setSelectedReason={(reason: ReasonResource) => setSelectedReason(reason)}
              inputValue={inputValue}
              onInputValueChange={onChangeReasonField}
              onBlur={onBlurAction}
              noteFormElementChildren={
                <FormElement
                  className="no-margin"
                  fieldId="notes"
                  labelText={translate("Appointments.Note")}
                  errorText={notOnlyAsciiInNoteError}
                >
                  <Textarea
                    aria-label="notes"
                    value={notes}
                    invalid={notOnlyAsciiInNoteError !== ""}
                    onChange={onChangeNoteField}
                    rows={5}
                    id="notes"
                    disabled={isLoading}
                    characterWarningLimit={NOTE_CHARACTERS_LIMIT}
                    data-testid="amend-appointment-note"
                  />
                </FormElement>
              }
              statusFormElementChildren={
                hasUpdateStatusPermissions && (
                  <FormElement className="no-margin" fieldId="status" labelText={translate("Appointments.Status")}>
                    <RadioButtonGroup
                      data-testid="radioGroup"
                      onChange={(e) => handleChangeStatus(e)}
                      value={selectedStatus}
                      defaultChecked
                      defaultValue={currentStatus}
                    >
                      <RadioButton aria-label={currentStatus} id={currentStatus} value={currentStatus}>
                        {isLoading ? (
                          <Skeleton.Item className={styles.skeletonSmallsize} />
                        ) : (
                          renamingStatus(currentStatus)
                        )}
                      </RadioButton>
                      {statusRelationships?.nextStatuses?.map((nextStatus) => (
                        <RadioButton
                          data-testid={`${nextStatus}-radio`}
                          aria-label={nextStatus}
                          id={nextStatus}
                          value={nextStatus}
                          key={nextStatus}
                        >
                          {!isLoading && nextStatus}
                        </RadioButton>
                      ))}
                    </RadioButtonGroup>
                  </FormElement>
                )
              }
              needToCancelChildren={
                !hideCancelAppointmentOption && (
                  <div className={styles["need-to-cancel-message"]}>
                    <div className={styles["need-to-cancel-message__label"]}>
                      {translate("Appointments.NeedToCancelThisAppointment")}
                    </div>

                    <Button borderless={true} onClick={handleOpenUnsavedWarning} data-testid="cancel-appointment-btn">
                      {translate("Appointments.CancelAppointment")}
                    </Button>
                  </div>
                )
              }
              formButtonsChildren={
                <FormButtons className={styles["amend-button"]}>
                  <ButtonGroup>
                    <Button
                      data-testid="save-updated-appointment"
                      onClick={saveAppointment}
                      type="button"
                      variant="filled"
                      disabled={isLoading}
                    >
                      Save
                    </Button>
                    <Button borderless={true} onClick={closeAmendDialog} type="button">
                      Discard
                    </Button>
                  </ButtonGroup>
                </FormButtons>
              }
            />
          </Content>
        </DialogInner>
      </Dialog>
      {showSaveChangesDialog && (
        <SaveChangesDialog
          showDialog={showSaveChangesDialog}
          closeDialog={closeSaveChangesDialog}
          onSaveChanges={handleSaveCancelAppointment}
        />
      )}
    </>
  );
};
export default AmendDialog;
