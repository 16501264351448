// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZtcJrogr82R2UKGiHZLW{color:#000;margin-left:unset !important;background:rgba(0,0,0,0);border:none}.ZtcJrogr82R2UKGiHZLW:hover{border-radius:3px;background:var(--neutral-90---border-bright, #DDE5E9)}.fDMOCexEkqvhaCD2CxZA{opacity:0;margin-left:unset !important}.PoKuQeiDq_kJ5H7ImbVF{color:var(--neutral-20-text, #2b3337);font-size:14px;font-style:normal;font-weight:400;line-height:20px}.SGpHuzMpnO13pDr7w_Ec.SGpHuzMpnO13pDr7w_Ec.SGpHuzMpnO13pDr7w_Ec{display:flex;flex-direction:column;width:166px;padding:16px 16px 0px 16px;align-items:flex-start;gap:10px;border-top:1px solid var(--neutral-90-border-bright, #dde5e9);color:var(--neutral-40);font-size:12px;font-style:normal;font-weight:400;line-height:20px}.yUBwPXAw871SAeSCetcP{color:var(--neutral-20-text, #2b3337);font-size:14px;font-style:normal;font-weight:400;line-height:20px}.Qo5JMYeLv7a0hCdQV6Rz{color:var(--neutral-20-text, #2b3337);font-size:14px;font-style:normal;font-weight:400;line-height:20px}.tpziVDA77Lj7qdZHcraF{color:var(--neutral-20-text, #2b3337);display:flex;align-items:center;gap:4px}`, "",{"version":3,"sources":["webpack://./src/components/AppointmentsDropdownMenu/AppointmentsDropdownMenu.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,4BAAA,CACA,wBAAA,CACA,WAAA,CAGF,4BACE,iBAAA,CACA,qDAAA,CAGF,sBACE,SAAA,CACA,4BAAA,CAGF,sBACE,qCAAA,CAEA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGF,gEACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,0BAAA,CACA,sBAAA,CACA,QAAA,CACA,6DAAA,CACA,uBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,qCAAA,CAEA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,qCAAA,CAEA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,qCAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropwown": `ZtcJrogr82R2UKGiHZLW`,
	"dropwownHidde": `fDMOCexEkqvhaCD2CxZA`,
	"dropdownItemTitle": `PoKuQeiDq_kJ5H7ImbVF`,
	"dropdownLabel": `SGpHuzMpnO13pDr7w_Ec`,
	"dropdownStatus": `yUBwPXAw871SAeSCetcP`,
	"statusItem": `Qo5JMYeLv7a0hCdQV6Rz`,
	"cancelStatusItems": `tpziVDA77Lj7qdZHcraF`
};
export default ___CSS_LOADER_EXPORT___;
