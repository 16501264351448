import { useTranslation } from "@emisgroup/application-intl";
import { Banner } from "@emisgroup/ui-banner";
import ErrorCircleOutline from "~icons/ic/outline-error-outline";
import styles from "./ErrorBanner.module.scss";

const ErrorBanner = () => {
  const { t: translate } = useTranslation();
  return (
    <Banner data-testid="error-banner" color="error" className={styles.bannerContainer}>
      <ErrorCircleOutline title="Error" />
      <div className={styles.bannerTitleContainer}>
        <h6 className={styles.bannerTitle}>{translate("Appointments.SomethingWentWrongErrorTitle")}</h6>
        <p className={styles.text}>{translate("Appointments.TryAgain")}</p>
      </div>
    </Banner>
  );
};

export default ErrorBanner;
