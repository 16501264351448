import { Stack } from "@emisgroup/ui-layouts";
import { FC } from "react";
import CalendarTodayTwoTone from "~icons/ic/twotone-calendar-today";
import styles from "./EmptyState.module.scss";

const EmptyState: FC = () => {
  return (
    <Stack className={styles.stackContainer} data-testid="empty page">
      <div className={styles.iconContainer}>
        <CalendarTodayTwoTone title="Empty" size="x-large" color="#3B78BD" />
      </div>
      <div className={styles.containerTitle}>
        <h3 className={styles.title}>No appointments</h3>
        <p className={styles.description}>This patient doesn’t have any upcoming or past appointments.</p>
      </div>
    </Stack>
  );
};

export default EmptyState;
