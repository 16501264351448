// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoHEMi23TYrNI36Zt1NU{justify-content:space-between;border:1px solid var(--positive)}.CoHEMi23TYrNI36Zt1NU div:last-child{margin-left:auto}.fqJ5t6EIFVhybWL9lVYk{border:1px solid var(--positive)}.KhG06al7UReBr5k_IqAn{border:1px solid var(--negative)}.ODm_DkfuD_jgaX6bX70Q{display:flex;align-items:center;gap:var(--spacing-large)}.uVLCvphZ6ORryfA57lnG{display:flex;gap:var(--spacing-xsmall)}.nSES7GVC94xVOCI6P_ZG{display:flex;align-items:center;gap:var(--spacing-xsmall);flex-wrap:wrap}.zyR5cfTixE0OLOH876jT{font-size:var(--font-size);font-weight:var(--font-weight-bold);line-height:var(--line-height);letter-spacing:0em;text-align:left;color:var(--positive)}.VlDNaZdcZ6WvPWTfw9vx{color:var(--positive)}.VwVSSrZEOmEgFphHeozM{color:var(--negative)}.zXP8KXTUXB3blzErG21O{color:var(--text)}.CKWQeqV5L_FF3N4BhqXO{font-size:var(--font-size);font-weight:var(--font-weight-bold);line-height:var(--line-height);letter-spacing:0em;text-align:left;color:var(--positive)}.uEKIPZYf6NvNz1ok0cQ_{font-size:var(--font-size);line-height:var(--line-height);letter-spacing:0em;text-align:left;color:var(--text)}`, "",{"version":3,"sources":["webpack://./src/components/common/ConfirmationMessage/Confirmation.module.scss"],"names":[],"mappings":"AAAA,sBACE,6BAAA,CACA,gCAAA,CAGF,qCACE,gBAAA,CAGF,sBACE,gCAAA,CAGF,sBACE,gCAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CACA,yBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,yBAAA,CACA,cAAA,CAGF,sBACE,0BAAA,CACA,mCAAA,CACA,8BAAA,CACA,kBAAA,CACA,eAAA,CACA,qBAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,0BAAA,CACA,mCAAA,CACA,8BAAA,CACA,kBAAA,CACA,eAAA,CACA,qBAAA,CAGF,sBACE,0BAAA,CACA,8BAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationContainer": `CoHEMi23TYrNI36Zt1NU`,
	"successConfirmation": `fqJ5t6EIFVhybWL9lVYk`,
	"errorConfirmation": `KhG06al7UReBr5k_IqAn`,
	"titleContainer": `ODm_DkfuD_jgaX6bX70Q`,
	"titleAndContentContainer": `uVLCvphZ6ORryfA57lnG`,
	"titleText": `nSES7GVC94xVOCI6P_ZG`,
	"statusTitle": `zyR5cfTixE0OLOH876jT`,
	"successTitle": `VlDNaZdcZ6WvPWTfw9vx`,
	"errorTitle": `VwVSSrZEOmEgFphHeozM`,
	"closeBtn": `zXP8KXTUXB3blzErG21O`,
	"confirmationSuccess": `CKWQeqV5L_FF3N4BhqXO`,
	"confirmationContent": `uEKIPZYf6NvNz1ok0cQ_`
};
export default ___CSS_LOADER_EXPORT___;
