// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gugwkWHmK0i84arILdSP{margin-bottom:var(--spacing-small)}.nKe0m4kcZEl02WslgNVX.nKe0m4kcZEl02WslgNVX.nKe0m4kcZEl02WslgNVX{display:flex;align-items:start;justify-content:center;flex-direction:column}.WJ93e35ZcYngALya_Bd7{color:var(--neutrals-text-base, #2b3337);font-size:16px;font-style:normal;font-weight:700;line-height:22px}.md2cQcFcv8_NZ207zqFD.md2cQcFcv8_NZ207zqFD.md2cQcFcv8_NZ207zqFD.md2cQcFcv8_NZ207zqFD{margin-left:0}`, "",{"version":3,"sources":["webpack://./src/components/ErrorBanner/ErrorBanner.module.scss"],"names":[],"mappings":"AAAA,sBACE,kCAAA,CAGF,gEACE,YAAA,CACA,iBAAA,CACA,sBAAA,CACA,qBAAA,CAGF,sBACE,wCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGF,qFACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `gugwkWHmK0i84arILdSP`,
	"bannerTitleContainer": `nKe0m4kcZEl02WslgNVX`,
	"bannerTitle": `WJ93e35ZcYngALya_Bd7`,
	"text": `md2cQcFcv8_NZ207zqFD`
};
export default ___CSS_LOADER_EXPORT___;
