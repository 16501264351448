import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../lib/constant/constant";
import { IUpdateAppointmentBody, IUpdateAppointmentResponse } from "../types/appointments";
import { AppointmentStatus } from "../types/status";
import axiosInstance from "../utils/axiosInstance";

const INCLUDE =
  "organisation,organisation.location,location,location.locationType,clinicians,clinicians.jobCategory,bookedByUser.jobCategory,bookedByUser," +
  "lastUpdatedByUser,lastUpdatedByUser.jobCategory,slotType,patient,dnaReason";

export enum Fieldset {
  minimum = "minimum",
  standard = "standard",
  full = "full",
  extended = "extended"
}

const getUpcomingAppointments = async (personId: string, greaterOrEquals: string, excludeCancelledFilter?: string) => {
  const response = await axiosInstance.get(`/persons/${personId}/appointments`, {
    params: {
      "page[number]": DEFAULT_PAGE_NUMBER,
      "page[size]": DEFAULT_PAGE_SIZE,
      fieldset: Fieldset.extended,
      filter: [greaterOrEquals, excludeCancelledFilter],
      sort: "startDateTime",
      include: INCLUDE
    },
    paramsSerializer: {
      indexes: null
    }
  });

  return response?.data;
};

const getPastAppointments = async (
  personId: string,
  lessThan: string,
  pageNumber = DEFAULT_PAGE_NUMBER,
  excludeCancelledFilter?: string
) => {
  const response = await axiosInstance.get(`/persons/${personId}/appointments`, {
    params: {
      "page[number]": pageNumber,
      "page[size]": DEFAULT_PAGE_SIZE,
      fieldset: Fieldset.extended,
      filter: [lessThan, excludeCancelledFilter],
      sort: "-startDateTime",
      include: INCLUDE
    },
    paramsSerializer: {
      indexes: null
    }
  });

  return response?.data;
};

const getAppointById = async (personId: string, appointmentId: string) => {
  const response = await axiosInstance.get(`/persons/${personId}/appointments/${appointmentId}`, {
    params: {
      include: INCLUDE,
      fieldset: Fieldset.extended
    }
  });

  return response?.data;
};

const updateAppointment = async (
  personId: string,
  appointmentId: string,
  reason?: string,
  notes?: string,
  status?: string,
  cancellationReason?: string
) => {
  const statusMappings = {
    Booked: AppointmentStatus.SlotAvailable,
    "Did not attend": AppointmentStatus.DNA,
    "Visited and seen": AppointmentStatus.Visited,
    "Visited and not seen": AppointmentStatus.VisitedNotIn,
    "Call complete": AppointmentStatus.TelephoneComplete,
    "Start video call": AppointmentStatus.VideoStartCall,
    "Video call complete": AppointmentStatus.VideoComplete,
    "No answer - failed encounter": AppointmentStatus.TelephoneNotIn
  };

  status = statusMappings[status] || status;
  const bodyRequest: IUpdateAppointmentBody = {
    type: "appointments",
    id: appointmentId,
    attributes: {
      reason,
      notes,
      status,
      cancellationReason
    }
  };

  const response = await axiosInstance.patch<IUpdateAppointmentResponse>(
    `/persons/${personId}/appointments/${appointmentId}`,
    {
      data: {
        ...bodyRequest
      }
    },
    {
      headers: {
        "Content-Type": "application/vnd.api+json"
      }
    }
  );

  return { data: response?.data.data, status: response?.status };
};

const getAppointmentStatuses = async () => {
  const response = await axiosInstance.get(`/appointments/statuses`, {});

  return response?.data;
};

export { getAppointById, getAppointmentStatuses, getPastAppointments, getUpcomingAppointments, updateAppointment };
