import { FC } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Tag } from "@emisgroup/ui-tag";
import { AppointmentStatus } from "../../types/status";

interface IAppointmentTagProps {
  status: string;
}

const AppointmentTag: FC<IAppointmentTagProps> = (props: IAppointmentTagProps) => {
  const { status } = props;
  const { t: translate } = useTranslation();
  switch (status) {
    case AppointmentStatus.Cancelled:
      return (
        <Tag color="--accent-3-70" textColor="--neutral-10" data-testid="cancelled-tag">
          {translate("Appointments.CancelledTag")}
        </Tag>
      );
    case AppointmentStatus.DNA:
      return (
        <Tag color="--negative-70" textColor="--neutral-10" data-testid="did-not-attend-tag">
          {translate("Appointments.NotAttendTag")}
        </Tag>
      );
    case AppointmentStatus.Arrived:
      return (
        <Tag color="--positive-80" textColor="--neutral-10" data-testid="arrived-tag">
          {translate("Appointments.ArrivedTag")}
        </Tag>
      );
    case AppointmentStatus.WalkedOut:
      return (
        <Tag color="--deco-kobi" textColor="--neutral-10" data-testid="walked-out-tag">
          {translate("Appointments.WalkedOutTag")}
        </Tag>
      );
    case AppointmentStatus.Late:
      return (
        <Tag color="--cautionary-80" textColor="--neutral-10" data-testid="late-tag">
          {translate("Appointments.Late")}
        </Tag>
      );
    case AppointmentStatus.InConsultation:
      return (
        <Tag color="--deco-pacific" textColor="--neutral-10" data-testid="in-consultation-tag">
          {translate("Appointments.InConsultationTag")}
        </Tag>
      );
    default:
      return <div data-testid="wrong-appointment-status"></div>;
  }
};

export default AppointmentTag;
