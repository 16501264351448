import { getContext, useFFClient, withFFProvider } from "@emisgroup/acp-utility-feature-flags";
import { getUserContext } from "@emisgroup/acp-utility-user-context";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import React, { ReactElement, useEffect, useState } from "react";
import { ILaunchDarklyProviderProps } from "./ILaunchDarklyProviderProps";

const LaunchDarklyProvider: React.FC<ILaunchDarklyProviderProps> = (
  props: ILaunchDarklyProviderProps
): ReactElement => {
  const { children } = props;
  const [ready, setReady] = useState(false);

  const userContext = getUserContext();
  const ldClient = useFFClient();

  useEffect(() => {
    userContext.getUserClaims().then((userClaims) => {
      if (ldClient) {
        // This sets the new user context at which points all flags should re-evalute to this user
        ldClient.identify(getContext(userClaims));
        setReady(true);
      }
    });
  });

  if (!ready) {
    return <ProgressSpinner />;
  }

  return <>{children}</>;
};

export default withFFProvider({})(LaunchDarklyProvider) as React.FC<ILaunchDarklyProviderProps>;
