export const getDateFormat = (date: string): string => {
  if (!date) return;
  const objectDate = new Date(date);

  const day = objectDate.toLocaleString("en-Uk", { day: "2-digit" });
  const month = objectDate.toLocaleString("en-Uk", { month: "short" });
  const year = objectDate.toLocaleString("en-Uk", { year: "numeric" });

  return day + "-" + month + "-" + year;
};

export const getTimeFormat = (date: string): string => {
  if (!date) return;

  const objectDate = new Date(date);

  const formatter = new Intl.DateTimeFormat("en-GB", { hour: "2-digit", minute: "2-digit" });
  const formattedTime = formatter.format(objectDate);
  const [hour, minutes] = formattedTime.split(":");

  return hour + ":" + minutes;
};
