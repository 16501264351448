import { getPatientContext } from "@emisgroup/acp-utility-patient-context";
import { useCallback, useEffect, useState } from "react";
import { PatientUserContext } from "../../lib/context/PatientUserContext";
import { IApplicationContext } from "../../lib/models/IApplicationContext";

const useApplicationContext = () => {
  const patientContext = getPatientContext();
  const [personGuid, setPersonGuid] = useState<string | undefined>(patientContext?.personGuid);

  const devAuth = new URLSearchParams(window.location.search).get("dev-auth");
  const devMock = new URLSearchParams(window.location.search).get("dev-mock");

  let mockedAuth = devAuth !== null && devAuth !== "false";
  let mockedDev = false;
  // validating mocking whole dev
  // And setting netlify mock api if it's true
  if (devMock && devMock == "true") {
    mockedAuth = true;
    mockedDev = true;
  }

  const patientUserContext = new PatientUserContext(mockedAuth);

  let [applicationContext, setApplicationContext] = useState<IApplicationContext>(
    patientUserContext.getApplicationContext(mockedDev)
  );
  // Subscribe to patient swap event
  const personSwapSubscription = patientContext?.subscribeToPersonSwap(() => {
    // Returns the new patient Guid
    const newPersonGuid = patientContext?.personGuid;
    if (newPersonGuid !== personGuid) {
      setPersonGuid(newPersonGuid);
    }
  });

  const patientBannerStateSubscription = patientContext?.subscribeToPatientBannerState(() => {
    const patient = patientUserContext.getApplicationContext(mockedDev)?.patient;
    setApplicationContext((appContext) => ({
      ...appContext,
      patient: patient
    }));
  });

  const onRefreshButtonClick = useCallback(() => {
    setApplicationContext({
      ...applicationContext,
      patient: {
        ...applicationContext?.patient,
        lastUpdated: new Date().getTime()
      }
    });

    if (getPatientContext()?.updateFlagToShowPatientRecordUpdated) {
      getPatientContext().updateFlagToShowPatientRecordUpdated();
    }
  }, [applicationContext]);

  useEffect(() => {
    return () => {
      personSwapSubscription?.unsubscribe();
      patientBannerStateSubscription?.unsubscribe();
    };
  }, [patientContext?.personGuid, personSwapSubscription, patientBannerStateSubscription]);

  return { personGuid, applicationContext, onRefreshButtonClick };
};

export default useApplicationContext;
