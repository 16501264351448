import { useTranslation } from "@emisgroup/application-intl";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Content, Dialog, DialogInner, Header } from "@emisgroup/ui-dialog";
import { Switcher } from "@emisgroup/ui-layouts";
import Warning from "~icons/ic/baseline-warning-amber";
import styles from "./SaveChangesDialog.module.scss";

interface Props {
  showDialog?: boolean;
  onSaveChanges?: () => void;
  closeDialog?: () => void;
}

const SaveChangesDialog = ({ showDialog, closeDialog, onSaveChanges }: Props) => {
  const { t: translate } = useTranslation();

  return (
    <Dialog onOpenChange={closeDialog} open={showDialog} data-testid="save-changes-dialog">
      <DialogInner aria-label="form" className={styles["dialog-wrapper"]}>
        <Header className={styles.header}>
          <Warning size="large" title="" color="var(--cautionary-70)" className={styles.warningIcon} />
          <div className={styles.headerTitle}>{translate("Appointments.SaveChangesTitle")}</div>
        </Header>
        <Content style={{ padding: "unset" }} data-testid="save-changes-dialog-content">
          <Switcher gap="0.5rem" threshold="0" className={styles.confirmationMessage}>
            {translate("Appointments.SaveChangedWarningMessage")}
          </Switcher>
          <ButtonGroup className={styles["action-buttons"]}>
            <Button data-testid="go-back-to-amend-appointment" onClick={closeDialog} type="button" borderless={true}>
              {translate("Appointments.GoBack")}
            </Button>
            <Button onClick={onSaveChanges} type="button" variant="warning" data-testid="confirm-save-changes-btn">
              {translate("Appointments.Save")}
            </Button>
          </ButtonGroup>
        </Content>
      </DialogInner>
    </Dialog>
  );
};

export default SaveChangesDialog;
