import { Stack } from "@emisgroup/ui-layouts";
import { FC } from "react";
import UserTwoTone from "~icons/ic/twotone-person";
import styles from "./PatientNotSelected.module.scss";
import { useTranslation } from "@emisgroup/application-intl";

const PatientNotSelected: FC = () => {
  const { t: translate } = useTranslation();
  return (
    <Stack className={styles.stackContainer} data-testid="patient-not-selected-page">
      <div className={styles.iconContainer}>
        <UserTwoTone title="Patient Not Selected" size="x-large" color="var(--primary)" />
      </div>
      <div className={styles.containerTitle}>
        <h3 className={styles.title}>{translate("Appointments.PatientNotSelected")}</h3>
        <p className={styles.description}>{translate("Appointments.PatientNotSelectedDescription")}</p>
      </div>
    </Stack>
  );
};

export default PatientNotSelected;
