import { useTranslation } from "@emisgroup/application-intl";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Content, Dialog, DialogInner, Header } from "@emisgroup/ui-dialog";
import { FormButtons } from "@emisgroup/ui-form";
import { Switcher } from "@emisgroup/ui-layouts";
import { Skeleton } from "@emisgroup/ui-skeleton";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Warning from "~icons/ic/baseline-warning-amber";
import { getAppointById, updateAppointment } from "../../services/appointments";
import { getAppointmentRelationshipsData } from "../../services/dataMapper";
import { getReasons } from "../../services/reasons";
import {
  AppointmentDetailsApiResponse,
  AppointmentIncludedRelationships,
  AppointmentResource
} from "../../types/appointments";
import { ResponseStatus } from "../../types/axiosResponse";
import { AppointmentReasonApiResponse, ReasonResource } from "../../types/reasons";
import { AppointmentStatus } from "../../types/status";
import useMobileView from "../../utils/hooks/useMobileView";
import useApplicationContext from "../../utils/hooks/useApplicationContext";
import ErrorBanner from "../ErrorBanner/ErrorBanner";
import DialogForm from "../common/DialogForm/DialogForm";
import PatientDetails from "../common/PatientDetails/PatientDetails";
import styles from "./CancelDialog.module.scss";
import { hasFieldOnlyAsciiCharacters } from "../../utils/fieldValidator";
import { LOCAL_PATIENT_ID } from "../../utils/constants";

interface Props {
  appointmentId?: string;
  showDialog?: boolean;
  closeDialog?: () => void;
  showConfirmationMessage?: (
    isConfirmationOpen: boolean,
    confirmationText: string,
    setIsConfirmationSuccess?: boolean
  ) => void;
  updateAppointmentTag?: (statusTag: string) => void;
}

const SCREEN_WIDTH = 576;

const CancelDialog = ({
  appointmentId,
  showDialog,
  closeDialog,
  showConfirmationMessage,
  updateAppointmentTag
}: Props) => {
  const { t: translate } = useTranslation();

  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentResource>(null);
  const [appointmentDetailsIncluded, setAppointmentDetailsIncluded] = useState<any[]>([]);
  const [cancelReasons, setCancelReasons] = useState<ReasonResource[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isReasonError, setIsReasonError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState<ReasonResource>();
  const [isError, setIsError] = useState<boolean>(false);
  const [reasonError, setReasonError] = useState<string | string[]>("");

  const { isMobileView } = useMobileView(SCREEN_WIDTH);
  const { personGuid } = useApplicationContext();

  const retrieveAppointmentDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const result: AppointmentDetailsApiResponse = await getAppointById(
        process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid,
        appointmentId
      );

      setAppointmentDetails(result?.data);
      setAppointmentDetailsIncluded(result?.included);
    } catch (error) {
      console.error(`Error is in CancelDialog component : ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [personGuid, appointmentId]);

  const retrieveCancelReasons = useCallback(async () => {
    try {
      const result: AppointmentReasonApiResponse = await getReasons(true);

      setCancelReasons(result?.data);
      setIsReasonError(false);
    } catch {
      setIsReasonError(true);
    }
  }, [personGuid, appointmentId]);

  const cancelAppointment = async () => {
    if (reasonError) {
      return;
    }

    try {
      const result = await updateAppointment(
        process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid,
        appointmentId,
        undefined,
        undefined,
        AppointmentStatus.Cancelled,
        inputValue
      );

      if (result.status == ResponseStatus.OK) {
        updateAppointmentTag(AppointmentStatus.Cancelled);
        showConfirmationMessage(true, translate("Appointments.Cancelled"), true);
      }

      setReasonError("");
      setIsError(false);
      closeDialog();
    } catch {
      setIsError(true);
    }
  };

  const filteredDetails: AppointmentIncludedRelationships = useMemo(() => {
    const filteredDetails = getAppointmentRelationshipsData(appointmentDetails, appointmentDetailsIncluded);

    return filteredDetails;
  }, [appointmentDetails, appointmentDetailsIncluded]);

  const addCustomReasonToReasonsList = (newReason: string) => {
    const foundReason: ReasonResource = cancelReasons?.find((r) => r.attributes.description === newReason);

    if (!foundReason && newReason)
      setCancelReasons((prevValue) => [
        ...prevValue,
        {
          id: uuidv4(),
          attributes: {
            description: newReason,
            reasonType: "Cancellation"
          },
          type: "reasons"
        }
      ]);
  };

  const onReasonValueChanged = (reasonValue: string) => {
    setInputValue(reasonValue);

    const hasReasonOnlyAscii = hasFieldOnlyAsciiCharacters(inputValue);
    if (!inputValue || !hasReasonOnlyAscii) setReasonError("");
  };

  const onBlurAction = (fieldName: string) => {
    if (!inputValue) {
      setReasonError(`${translate("Appointments.ReasonReqiuredErrorMessage")}`);
    }

    const hasReasonOnlyAscii = hasFieldOnlyAsciiCharacters(inputValue);
    if (fieldName === "reason-picker" && !hasReasonOnlyAscii) {
      setReasonError(`${translate("Appointments.Reason")} ${translate("Appointments.NonAsciiMessage")}`);
    }
  };

  useEffect(() => {
    retrieveAppointmentDetails();
    retrieveCancelReasons();
  }, [appointmentId]);

  useEffect(() => {
    addCustomReasonToReasonsList(appointmentDetails?.attributes.cancellationReason);

    const foundReason: ReasonResource = cancelReasons?.find(
      (r) => r.attributes.description === selectedReason?.attributes.description
    );

    if (foundReason) {
      setSelectedReason(foundReason);
      setInputValue(foundReason.attributes.description);
    }
  }, [cancelReasons, appointmentDetails, selectedReason]);

  return (
    <Dialog onOpenChange={closeDialog} open={showDialog} data-testid="cancel-dialog">
      <DialogInner aria-label="form" className={styles["dialog-wrapper"]}>
        <Header className={styles.header}>
          <Warning size="large" title="" color="var(--cautionary-70)" className={styles.warningIcon} />
          <div className={styles.headerTitle}>{translate("Appointments.CancelAppointment")}</div>
        </Header>
        {isError && (
          <Content className={styles.contentContainer}>
            <div className={styles.bannerError}>
              <ErrorBanner />
            </div>
          </Content>
        )}
        <Content style={{ padding: "unset" }} data-testid="cancel-dialog-content">
          <Switcher gap="0.5rem" threshold="0" className={styles.confirmationMessage}>
            {translate("Appointments.CancelAppointmentInfoText")}
          </Switcher>
          <PatientDetails
            appointmentId={appointmentId}
            appointmentDetails={appointmentDetails}
            filteredDetails={filteredDetails}
            isSkeletonLoading={isLoading}
          >
            <Switcher gap="0.5rem" threshold="var(--threshold-small)" className={styles["patient-details-grid-row"]}>
              <div
                className={classNames([
                  styles["patient-details-grid-row__label"],
                  {
                    [styles["patient-details-grid-row__label--desktop"]]: !isMobileView,
                    [styles["patient-details-grid-row__label--mobile"]]: isMobileView
                  }
                ])}
              >
                {translate("Appointments.Reason")}
              </div>
              <div className={styles["patient-details-grid-row__patient-detail"]}>
                {isLoading ? <Skeleton.Item className={styles["skeleton"]} /> : appointmentDetails?.attributes?.reason}
              </div>
            </Switcher>
          </PatientDetails>
          <DialogForm
            comboboxLabel={translate("Appointments.Combobox")}
            comboboxPlaceholder={translate("Appointments.CancellationReasonPlaceholder")}
            formLabel={translate("Appointments.CancellationReason")}
            hasReasonError={isReasonError}
            errorText={reasonError}
            isLoading={isLoading}
            reasons={cancelReasons}
            reasonErrorMessage={translate("Appointments.ReasonError")}
            selectedReason={selectedReason}
            setSelectedReason={(reason: ReasonResource) => setSelectedReason(reason)}
            inputValue={inputValue}
            onInputValueChange={onReasonValueChanged}
            onBlur={onBlurAction}
            formButtonsChildren={
              <FormButtons className={styles["cancel-buttons"]}>
                <ButtonGroup>
                  <Button data-testid="save-updated-appointment" onClick={closeDialog} type="button" borderless={true}>
                    Go back
                  </Button>
                  <Button
                    onClick={cancelAppointment}
                    type="button"
                    variant="warning"
                    data-testid="cancel-updated-appointment"
                    disabled={isLoading}
                  >
                    Cancel appointment
                  </Button>
                </ButtonGroup>
              </FormButtons>
            }
          />
        </Content>
      </DialogInner>
    </Dialog>
  );
};

export default CancelDialog;
