import { FC, useEffect } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Badge } from "@emisgroup/ui-badge";
import { GridList, Selection } from "@emisgroup/ui-list";
import AppointmentsListItem from "../AppointmentsListItem/AppointmentsListItem";
import ErrorCircle from "~icons/ic/outline-error";
import ErrorState from "../ErrorState/ErrorState";
import styles from "./AppointmentsList.module.scss";
import { AppointmentIncludedRelationships, AppointmentResource, StatusResource } from "../../types/appointments";
import { AppointmentStatus } from "../../types/status";
import { InfiniteScrollList } from "@emisgroup/infinite-scroll";

interface Props {
  label: string;
  havePastAppointments?: boolean;
  appointmentsData?: Array<AppointmentResource>;
  isError?: boolean;
  errorFrom?: string;
  isOpenDetailsPanel?: boolean;
  relationships?: AppointmentIncludedRelationships;
  selectedAppointmentId: string;
  statusList: StatusResource[];
  selectedAppointment: Selection;
  isUndoClicked: boolean;
  undoAppointmentId: string;
  totalCount: number;
  hasUserAppointmentsWritePermision?: boolean;
  openDetailsPanel: (open: boolean, appointmentId: string, index: number, isPastAppointment: boolean) => void;
  handleUpdateStatus: (status: AppointmentStatus, appointmentId: string) => void;
  updateAppointmentAttributes?: (appointmentId: string, reason: string, notes: string) => void;
  setSelectedAppointment: (key: Selection) => void;
  showConfirmationMessage: (
    isShowConfirmation: boolean,
    confirmationText: string,
    setIsConfirmationSuccess?: boolean,
    isStatusUpdated?: boolean
  ) => void;
  setIsUndoClicked: (isUndo: boolean) => void;
  setShowConfirmationWithoutAction: (showConfirmationWithoutAction: boolean) => void;
  setCloseConfirmation: (isClose: boolean) => void;
  handleClickTryAgain: (clicked: boolean) => void;
  hasNextPage?: boolean;
  triggerNextPage?: () => void;
  isBookAppointmentAppOpen?: boolean;
}

const AppointmentsList: FC<Props> = (props: Props): React.JSX.Element => {
  const { t: translate } = useTranslation();
  const {
    label,
    appointmentsData,
    relationships,
    havePastAppointments = false,
    openDetailsPanel,
    isOpenDetailsPanel,
    isError,
    selectedAppointmentId,
    selectedAppointment,
    totalCount,
    hasUserAppointmentsWritePermision,
    handleUpdateStatus,
    errorFrom,
    updateAppointmentAttributes,
    statusList,
    setSelectedAppointment,
    showConfirmationMessage,
    isUndoClicked,
    undoAppointmentId,
    setIsUndoClicked,
    setShowConfirmationWithoutAction,
    setCloseConfirmation,
    handleClickTryAgain,
    hasNextPage,
    triggerNextPage,
    isBookAppointmentAppOpen
  } = props;

  useEffect(() => {
    if (!isOpenDetailsPanel) setSelectedAppointment(new Set([""]));
    if (selectedAppointmentId && isOpenDetailsPanel) setSelectedAppointment(new Set([selectedAppointmentId]));
  }, [isOpenDetailsPanel, selectedAppointmentId]);

  return (
    <div data-testid="appointments-list">
      <div key={label} className={styles.listGroupHeader}>
        <span>{translate(label)}</span>
        <span className={styles.avatarContainer}>
          {isError ? (
            <ErrorCircle size="small" title={translate("Appointments.Error")} color="#DC3546" />
          ) : (
            <Badge children={totalCount.toString()} className={styles.avatar} data-testid="header-count-avatar" />
          )}
        </span>
      </div>
      {isError ? (
        <ErrorState
          isSeparateError
          errorOn={errorFrom}
          isOpened={isOpenDetailsPanel}
          setIsTryAgainClick={handleClickTryAgain}
        />
      ) : (
        <div>
          {appointmentsData?.length === 0 && !havePastAppointments && (
            <div className={styles.emptyUpcoming}>{translate("Appointments.EmptyUpcomingList")}</div>
          )}
          {appointmentsData?.length === 0 && havePastAppointments && (
            <div className={styles.emptyUpcoming}>{translate("Appointments.EmptyPastListPast")}</div>
          )}
          <GridList
            aria-label={"Appointments"}
            disallowEmptySelection
            selectionBehavior="replace"
            selectionMode="single"
            selectedKeys={selectedAppointment}
            onSelectionChange={setSelectedAppointment}
            data-testid="list-box-container"
            className={styles["list-box-container"]}
          >
            {appointmentsData?.map((appointment, index) => (
              <GridList.Item key={appointment?.id} aria-label={appointment?.id}>
                <AppointmentsListItem
                  appointmentData={appointment}
                  filteredData={relationships}
                  key={appointment.id}
                  isPastAppointments={havePastAppointments}
                  openDetailsPanel={openDetailsPanel}
                  selectedItemIndex={index}
                  isOpenDetailsPanel={isOpenDetailsPanel}
                  selectedAppointmentId={selectedAppointmentId}
                  hasUserAppointmentsWritePermision={hasUserAppointmentsWritePermision}
                  handleUpdateStatus={handleUpdateStatus}
                  updateAppointmentAttributes={updateAppointmentAttributes}
                  statusList={statusList}
                  showConfirmationMessage={showConfirmationMessage}
                  isUndoClicked={isUndoClicked}
                  setIsUndoClicked={setIsUndoClicked}
                  undoAppointmentId={undoAppointmentId}
                  setShowConfirmationWithoutAction={setShowConfirmationWithoutAction}
                  setCloseConfirmation={setCloseConfirmation}
                  isBookAppointmentAppOpen={isBookAppointmentAppOpen}
                />
              </GridList.Item>
            ))}
          </GridList>
        </div>
      )}
      <InfiniteScrollList
        className={styles.overrideBottomBorder}
        hasMoreData={hasNextPage}
        loadMoreData={triggerNextPage}
        spinnerDelay={100}
        veriticalSpinner={false}
        hideEndOfList={label === translate("Appointments.UpcomingLabel")}
      />
    </div>
  );
};

export default AppointmentsList;