import {
  AppointmentIncludedRelationships,
  AppointmentResource,
  ClinicalUserResource,
  DnaReasonResource,
  GenericRelationship,
  JobCategoryResource,
  LocationResource,
  OrganisationResource,
  PatientResource,
  SlotTypeResource,
  StatusResource
} from "../types/appointments";
import { AppointmentRelationshipNames, RelationshipType } from "../types/relationship";
import { AppointmentStatus, AppointmentStatusType, StatusesToDisplay } from "../types/status";

export const getResourceDataInAppointmentRelationships = (
  appointmentsIncludedRelationships: any[],
  type: RelationshipType,
  id: string
) => appointmentsIncludedRelationships?.find((item) => item.type === type && item.id === id);

const getFoundedItem = (appointmentsIncludedRelationships: any[], itemId: string) => {
  return appointmentsIncludedRelationships?.find((bookedRelationship) => bookedRelationship?.id === itemId);
};

function getGenericResource<T>(
  appointmentsIncludedRelationships: any[],
  relationshipData: GenericRelationship,
  type: RelationshipType,
  isLocations: boolean = false
): T {
  if (!relationshipData?.id) return;

  const foundItem = getFoundedItem(appointmentsIncludedRelationships, relationshipData.id);

  if (isLocations && !foundItem) {
    return [] as T;
  } else if (!foundItem) {
    return {} as T;
  }

  const itemInfo = getResourceDataInAppointmentRelationships(
    appointmentsIncludedRelationships,
    type,
    foundItem?.id
  ) as T;

  if (itemInfo) {
    return itemInfo;
  }
}

const getLocationsData = (
  appointmentsIncludedRelationships: any[],
  relationshipDatas: GenericRelationship[]
): LocationResource[] => {
  let filteredLocations: LocationResource[] = [];

  relationshipDatas.forEach((relationshipData) => {
    const locationInfo = getGenericResource<LocationResource>(
      appointmentsIncludedRelationships,
      relationshipData,
      RelationshipType.LOCATION,
      true
    );

    filteredLocations.push({ ...locationInfo });
  });

  return filteredLocations;
};

const getMappedData = (
  filteredData: AppointmentIncludedRelationships,
  appointment: AppointmentResource,
  appointmentsIncludedRelationships: any[]
) => {
  const appointmentId = appointment?.id;
  const relationships = appointment?.relationships;

  for (const relationship in relationships) {
    switch (relationship) {
      case AppointmentRelationshipNames.bookedByUser:
        filteredData.bookedByUser = getGenericResource<ClinicalUserResource>(
          appointmentsIncludedRelationships,
          relationships[relationship].data,
          RelationshipType.CLINICAL_USER
        );
        break;
      case AppointmentRelationshipNames.clinicians:
        getCliniciansArrayData(appointmentId, relationships[relationship].data);
        break;
      case AppointmentRelationshipNames.dnaReason:
        filteredData.dnaReason = getGenericResource<DnaReasonResource>(
          appointmentsIncludedRelationships,
          relationships[relationship].data,
          RelationshipType.ERN_EMIS_TERM_CODE
        );
        break;
      case AppointmentRelationshipNames.lastUpdatedByUser:
        filteredData.lastUpdatedByUser = getGenericResource<ClinicalUserResource>(
          appointmentsIncludedRelationships,
          relationships[relationship].data,
          RelationshipType.CLINICAL_USER
        );
        break;
      case AppointmentRelationshipNames.locations:
        filteredData.locations = getLocationsData(appointmentsIncludedRelationships, relationships[relationship].data);
        break;
      case AppointmentRelationshipNames.organisation:
        filteredData.organisation = getGenericResource<OrganisationResource>(
          appointmentsIncludedRelationships,
          relationships[relationship].data,
          RelationshipType.ORGANISATION
        );
        break;
      case AppointmentRelationshipNames.patient:
        filteredData.patient = getGenericResource<PatientResource>(
          appointmentsIncludedRelationships,
          relationships[relationship].data,
          RelationshipType.PATIENTS
        );
        break;
      case AppointmentRelationshipNames.slotType:
        getSlotTypesArrayData(appointmentId, relationships[relationship].data);
        break;
      case AppointmentRelationshipNames.statuses:
        filteredData.statuses = getGenericResource<StatusResource>(
          appointmentsIncludedRelationships,
          relationships[relationship].data,
          RelationshipType.STATUSES
        );
        break;
      default:
        break;
    }
  }

  function getCliniciansArrayData(appointmentId: string, relationshipDatas: GenericRelationship[]) {
    relationshipDatas.forEach((relationshipData) => {
      const clinicianInfo = getGenericResource<ClinicalUserResource>(
        appointmentsIncludedRelationships,
        relationshipData,
        RelationshipType.CLINICAL_USER,
        false
      );

      filteredData.clinicians.push({ appointmentId, ...clinicianInfo });

      const jobCategoryInfo = getGenericResource<JobCategoryResource>(
        appointmentsIncludedRelationships,
        clinicianInfo?.relationships?.jobCategory?.data,
        RelationshipType.JOB_CATEGORY,
        true
      );

      filteredData.jobCategory.push({ clinicianId: relationshipData.id, ...jobCategoryInfo });

      const organizationInfo = getGenericResource<OrganisationResource>(
        appointmentsIncludedRelationships,
        clinicianInfo?.relationships?.organisation?.data,
        RelationshipType.ORGANISATION,
        false
      );

      filteredData.organisation = { ...organizationInfo };
    });
  }

  function getSlotTypesArrayData(appointmentId: string, relationshipData: GenericRelationship) {
    const slotType = getGenericResource<SlotTypeResource>(
      appointmentsIncludedRelationships,
      relationshipData,
      RelationshipType.SLOT_TYPE,
      false
    );

    filteredData.slotTypes.push({
      appointmentId: appointmentId,
      ...slotType
    });
  }

  return { ...filteredData };
};
export const getAppointmentRelationshipsData = (
  appointments: AppointmentResource[] | AppointmentResource,
  appointmentsIncludedRelationships: any[]
): AppointmentIncludedRelationships => {
  let filteredData: AppointmentIncludedRelationships = {
    bookedByUser: {},
    clinicians: [],
    dnaReason: {},
    lastUpdatedByUser: {},
    locations: [],
    organisation: {},
    patient: {},
    slotTypes: [],
    statuses: {},
    jobCategory: []
  };

  if (Array.isArray(appointments)) {
    appointments.forEach((appointment) => {
      const appointmentMappedData = getMappedData(filteredData, appointment, appointmentsIncludedRelationships);

      filteredData = { ...appointmentMappedData };
    });
  } else {
    const appointmentMappedData = getMappedData(filteredData, appointments, appointmentsIncludedRelationships);

    filteredData = { ...appointmentMappedData };
  }

  return { ...filteredData };
};

export const getStatusesList = (
  statuses: StatusResource | StatusResource[],
  type: AppointmentStatusType,
  currentStatusId: string
): {
  nextStatuses: string[];
  cancelStatus: string;
} => {
  if (!statuses || !type) return;

  const ensureArray = Array.isArray(statuses) ? statuses : [statuses];

  const getStatusArrayByType = (
    statusArray: StatusResource[]
  ): {
    nextStatuses: string[];
    cancelStatus: string;
  } => {
    const filterByType = statusArray
      .filter((status) => status.attributes.statusType === type)
      .map((status) => status.id);

    const currentStatus = statusArray.filter((status) => status.id === currentStatusId)[0];
    if (!currentStatus) return { nextStatuses: [], cancelStatus: undefined };

    const filterStatuses: string[] = currentStatus.relationships.nextStatus.data
      .filter((status) => filterByType.includes(status.id))
      .map((status) => status.id);

    const cancelStatus = currentStatus.relationships.cancelStatus.data.id;

    return { nextStatuses: filterStatuses, cancelStatus: cancelStatus != currentStatus.id ? cancelStatus : undefined };
  };

  const getStatusArrayRelationships = (
    statusArray: StatusResource[]
  ): {
    nextStatuses: string[];
    cancelStatus: string;
  } => {
    const currentStatus = statusArray.filter((status) => status.id === currentStatusId)[0];
    if (!currentStatus) return { nextStatuses: [], cancelStatus: undefined };

    const cancelStatus = currentStatus.relationships?.cancelStatus.data.id;

    return {
      nextStatuses: currentStatus.relationships?.nextStatus?.data.map((status) => status.id),
      cancelStatus: cancelStatus != currentStatus.id ? cancelStatus : undefined
    };
  };
  const getStatusList = (
    status: string
  ): {
    nextStatuses: string[];
    cancelStatus: string;
  } => {
    return status === AppointmentStatus.SlotAvailable
      ? getStatusArrayByType(ensureArray)
      : getStatusArrayRelationships(ensureArray);
  };

  const statusRelationship = getStatusList(currentStatusId);
  const filteredStatus = statusRelationship.nextStatuses?.map((status) => {
    return renamingStatus(status);
  });

  return { nextStatuses: filteredStatus, cancelStatus: renamingStatus(statusRelationship.cancelStatus) };
};

export const renamingStatus = (updatedStatus: string) => {
  switch (updatedStatus) {
    case AppointmentStatus.SlotAvailable:
      return StatusesToDisplay.Booked;
    case AppointmentStatus.DNA:
      return StatusesToDisplay.DidNotAttend;
    //For home visit
    case AppointmentStatus.Visited:
      return StatusesToDisplay.VisitedAndSeen;
    case AppointmentStatus.VisitedNotIn:
      return StatusesToDisplay.VisitedAndNotSeen;
    //For telephone
    case AppointmentStatus.TelephoneComplete:
      return StatusesToDisplay.CallComplete;
    case AppointmentStatus.TelephoneNotIn:
      return StatusesToDisplay.NoAnswerFailedEncounter;
    //For video
    case AppointmentStatus.VideoStartCall:
      return StatusesToDisplay.StartVideoCall;
    case AppointmentStatus.VideoComplete:
      return StatusesToDisplay.VideoCallComplete;
    case AppointmentStatus.VideoNoAnswer:
      return StatusesToDisplay.NoAnswerFailedEncounter;
    default:
      return updatedStatus;
  }
};
