export enum AppointmentStatus {
  None = "None",
  SlotAvailable = "Slot Available",
  Arrived = "Arrived",
  SendIn = "Send In",
  Left = "Left",
  DNA = "DNA",
  WalkedOut = "Walked Out",
  Visited = "Visited",
  VisitedNotIn = "Visited - Not In",
  TelephoneComplete = "Telephone - Complete",
  TelephoneNotIn = "Telephone - Not In",
  Unknown = "Unknown",
  QuietSendIn = "Quiet Send In",
  StartCall = "Start Call",
  CannotBeSeen = "Cannot Be Seen",
  VideoStartCall = "Video - Start Call",
  VideoComplete = "Video - Complete",
  VideoNoAnswer = "Video - No Answer",
  Cancelled = "Cancelled",

  Late = "Late",
  InConsultation = "In consultation"
}

export enum AppointmentStatusType {
  Default = "Default",
  FaceToFaceSurgery = "Face to Face (Surgery)",
  TelephoneAudio = "Telephone/Audio",
  FaceToFaceHomeVisit = "Face to Face (Home Visit)",
  VideoWithAudio = "Video with Audio",
  Written = "Written (Including Online)",
  NotAnAppointment = "Not an Appointment"
}

export enum StatusesToDisplay {
  Booked = "Booked",
  DidNotAttend = "Did not attend",
  VisitedAndSeen = "Visited and seen",
  VisitedAndNotSeen = "Visited and not seen",
  CallComplete = "Call complete",
  NoAnswerFailedEncounter = "No answer - failed encounter",
  StartVideoCall = "Start video call",
  VideoCallComplete = "Video call complete"
}
