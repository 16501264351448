import {
  GetParcelStatus,
  MountParcel,
  ParcelType,
  PatientBannerState,
  SerializeError,
  UnmountParcel
} from "@emisgroup/acp-utility-common";
import { useFlags } from "@emisgroup/acp-utility-feature-flags";
import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-button";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { Selection } from "@emisgroup/ui-list";
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { getAppointmentStatuses, getPastAppointments, getUpcomingAppointments } from "../../services/appointments";
import { getAppointmentRelationshipsData } from "../../services/dataMapper";
import { AppointmentApiResponse, AppointmentResource, StatusResource } from "../../types/appointments";
import { AppointmentStatus } from "../../types/status";
import { isLastItemInList } from "../../utils/appointment";
import useApplicationContext from "../../utils/hooks/useApplicationContext";
import useDetailsPanelHandlers from "../../utils/hooks/useDetailsPanelHandlers";
import usePrevious from "../../utils/hooks/usePrevious";
import AppointmentsDetailsPanel from "../AppointmentsDetailsPanel/AppointmentsDetailsPanel";
import AppointmentsList from "../AppointmentsList/AppointmentsList";
import EmptyState from "../EmptyState/EmptyState";
import ErrorState from "../ErrorState/ErrorState";
import Loading from "../Loading/Loading";
import PatientNotSelected from "../PatientNotSelected/PatientNotSelected";
import Confirmation from "../common/ConfirmationMessage/Confirmation";
import RefreshButton from "../common/RefreshButton/RefreshButton";

import { format } from "date-fns";
import { DEFAULT_PAGE_NUMBER } from "../../lib/constant/constant";
import { LOCAL_PATIENT_ID, MEDIUM_BREAKPOINT, UserAuthorizations } from "../../utils/constants";
import useConfirmationActions from "../../utils/hooks/useConfirmationActions";
import useUserPermisions from "../../utils/hooks/useUserPermisions";
import { IsBookAppointmentAppOpen } from "../common/helper";
import style from "./Appointments.module.scss";

const Appointments: FC = () => {
  const { t: translate } = useTranslation();
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePreviousButton, seDisablePreviousButton] = useState(false);
  const [upcomingError, setUpcomingError] = useState(false);
  const [pastError, setPastError] = useState(false);
  const [isLoadingUpcomingAppts, setIsLoadingUpcomingAppts] = useState(false);
  const [isLoadingPastAppts, setIsLoadingPastAppts] = useState(false);
  const [displayWidth, setDisplayWidth] = useState(0);
  const [isSplitterOpen, setIsSplitterOpen] = useState(false);
  const [upcomingAppts, setUpcomingAppts] = useState<AppointmentResource[]>([]);
  const [pastAppts, setPastAppts] = useState<AppointmentResource[]>([]);
  const [upcomingIncluded, setUpcomingIncluded] = useState<any[]>([]);
  const [pastIncluded, setPastIncluded] = useState<any[]>([]);
  const [updateStatus, setUpdateStatus] = useState<AppointmentStatus>();
  const [appointmentIdUpdated, setAppointmentIdUpdated] = useState<string>("");
  const [reasonUpdated, setReasonUpdated] = useState<string>("");
  const [notesUpdated, setNotesUpdated] = useState<string>("");
  const { xgpAppointmentsUiShowbookappointment } = useFlags();
  const [isBookAppointmentAppOpen, setIsBookAppointmentAppOpen] = useState(false);

  const {
    isOpenDetailsPanel,
    selectedAppointmentId,
    selectedIndex,
    isPastAppointment,
    handleOpenDetailsPanel,
    handleGoToNextItem,
    handleGoToPreviousItem,
    handleCloseDetailsPanel
  } = useDetailsPanelHandlers({ pastAppts, upcomingAppts });

  const {
    handleCloseConfirmation,
    handleUndoAppointment,
    showConfirmation,
    setShowConfirmation,
    setIsConfirmationSuccess,
    isConfirmationSuccess,
    isUndoClicked,
    setIsUndoClicked,
    undoAppointmentId
  } = useConfirmationActions();
  const { personGuid, applicationContext } = useApplicationContext();
  const EXCLUDE_CANCELLED_FILTER = "notEqual(status,Cancelled)";

  const [statusList, setStatusList] = useState<StatusResource[]>();
  const [selectedUpcomingAppointments, setSelectedUpcomingAppointments] = useState<Selection>(new Set([""]));
  const [selectedPastAppointments, setSelectedPastAppointments] = useState<Selection>(new Set([""]));
  const previousAppointmentState = usePrevious(isPastAppointment);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [isRefreshButtonClicked, setIsRefreshButtonClicked] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [showConfirmationWithoutAction, setShowConfirmationWithoutAction] = useState(false);
  const [upcomingApptsCount, setUpcomingApptsCount] = useState(0);
  const [pastApptsCount, setPastApptsCount] = useState(0);
  const [excludeCancelled, setExcludeCancelled] = useState(true);
  const { authorizations } = useUserPermisions();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [hasNextPage, setHasNextPage] = useState(false);

  const appointmentsContainerRef = useRef<HTMLDivElement>(null);

  //disable book appointment button when right hand side app is open
  document.addEventListener("right-hand-side-app:open", () => {
    setIsSplitterOpen(true);
  });

  //enable book appointment button when right hand side app is closed
  document.addEventListener("right-hand-side-app:closed", () => {
    setIsSplitterOpen(false);
  });

  const isBannerLoaded =
    process.env.TARGET_ENVIRONMENT === "local"
      ? false
      : applicationContext?.patient.patientBannerState !== PatientBannerState.Loaded;

  const isLocalENV = process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid;
  const isPatientSelected = !!isLocalENV || (personGuid && personGuid !== "");

  const hasUserAppointmentsWritePermision: boolean = useMemo(() => {
    if (!authorizations) return;

    const authorization = authorizations.find((auth) => auth === UserAuthorizations.AppointmentsWrite);
    if (authorization) {
      return true;
    } else {
      return false;
    }
  }, [authorizations]);

  const hideDetailsPanelNavigationButtons = useMemo(
    () => appointmentsContainerRef.current?.offsetWidth >= MEDIUM_BREAKPOINT,
    [appointmentsContainerRef.current?.offsetWidth]
  );

  const allApptsEmptyAndNoError =
    upcomingAppts?.length === 0 && pastAppts?.length === 0 && !upcomingError && !pastError;
  const pastRelationshipsData = getAppointmentRelationshipsData(pastAppts, pastIncluded);
  const upcomingRelationshipsData = getAppointmentRelationshipsData(upcomingAppts, upcomingIncluded);

  const retrieveUpcomingAppointments = useCallback(
    async (excludeCancelledFilter?: string) => {
      setIsLoadingUpcomingAppts(true);
      setUpcomingError(false);
      const GREATER_OR_EQUALS = `greaterOrEqual(startDateTime,${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS")})`;

      try {
        const result: AppointmentApiResponse = await getUpcomingAppointments(
          isLocalENV,
          GREATER_OR_EQUALS,
          excludeCancelledFilter
        );

        setUpcomingAppts(result.data);
        setUpcomingIncluded(result.included);
        setUpcomingApptsCount(result.meta.totalAppointments);
        try {
          const result = await getAppointmentStatuses();

          setStatusList(result?.data);
        } catch (error) {
          console.error(`Fetching Error: ${error}`);
          setUpcomingError(true);
          setPastError(true);
        }
      } catch {
        setUpcomingError(true);
      } finally {
        setIsLoadingUpcomingAppts(false);
      }
    },
    [personGuid]
  );

  const retrievePastAppointments = useCallback(
    async (
      excludeCancelledFilter?: string,
      isPageReloaded = false,
      requestingPageNumber?: number,
      pastApptsData?: AppointmentResource[],
      includedData?: any[]
    ) => {
      if (!isPageReloaded) setIsLoadingPastAppts(true);
      setPastError(false);
      const LESS_THAN = `lessThan(startDateTime,${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS")})`;

      try {
        const result: AppointmentApiResponse = await getPastAppointments(
          isLocalENV,
          LESS_THAN,
          requestingPageNumber,
          excludeCancelledFilter
        );

        setHasNextPage(!!result.links.next);
        setPastAppts(isPageReloaded ? pastApptsData.concat(result.data) : result.data);
        setPastIncluded(isPageReloaded ? includedData.concat(result.included) : result.included);
        setPastApptsCount(result.meta.totalAppointments);
        try {
          const result = await getAppointmentStatuses();

          setStatusList(result?.data);
        } catch (error) {
          console.error(`Fetching Error: ${error}`);
          setUpcomingError(true);
          setPastError(true);
        }
      } catch {
        setPastError(true);
        setHasNextPage(false);
      } finally {
        setIsLoadingPastAppts(false);
      }
    },
    [personGuid]
  );

  const handleUpdateStatus = (status: AppointmentStatus, appointmentId: string) => {
    setUpdateStatus(status);
    setAppointmentIdUpdated(appointmentId);
    setShowConfirmation(false);
  };

  const handleUpdateAppointment = (
    showConfirmation: boolean,
    confirmationText: string,
    isConfirmationSuccess?: boolean,
    isStatusUpdated?: boolean
  ) => {
    setIsConfirmationSuccess(isConfirmationSuccess);
    setShowConfirmation(showConfirmation);
    setStatusUpdated(isStatusUpdated);
    setConfirmationText(confirmationText);
  };

  const handleUpdateAppointmentAttributes = (appointmentId: string, reason: string, notes: string) => {
    const upcomingClone = [...upcomingAppts];
    const pastClone = [...pastAppts];
    setAppointmentIdUpdated(appointmentId);
    setReasonUpdated(reason);
    setNotesUpdated(notes);
    const findUpcomingAppointmentIndex = upcomingClone.findIndex((appointment) => appointment.id === appointmentId);

    if (findUpcomingAppointmentIndex !== -1) {
      upcomingClone[findUpcomingAppointmentIndex].attributes.reason = reason;
      upcomingClone[findUpcomingAppointmentIndex].attributes.notes = notes;
      setUpcomingAppts(upcomingClone);
    }

    const findPastAppointmentIndex = pastClone.findIndex((appointment) => appointment.id === appointmentId);

    if (findPastAppointmentIndex !== -1) {
      pastClone[findPastAppointmentIndex].attributes.reason = reason;
      pastClone[findPastAppointmentIndex].attributes.notes = notes;
      setPastAppts(pastClone);
    }
  };

  const onChangeExcludeCancelledFilter = () => {
    const previousSelected = excludeCancelled;

    if (isOpenDetailsPanel) handleCloseDetailsPanel(false);
    if (showConfirmation) setShowConfirmation(false);

    setExcludeCancelled(!previousSelected);
    if (!previousSelected) {
      retrievePastAppointments(EXCLUDE_CANCELLED_FILTER);
      retrieveUpcomingAppointments(EXCLUDE_CANCELLED_FILTER);
    } else {
      retrievePastAppointments();
      retrieveUpcomingAppointments();
    }
  };

  useEffect(() => {
    setShowConfirmation(false);
    setExcludeCancelled(true);
  }, [personGuid, isPatientSelected, isRefreshButtonClicked]);

  useEffect(() => {
    setDisableNextButton(isLastItemInList(isPastAppointment, selectedIndex, pastAppts, upcomingAppts));

    if (selectedIndex === 0) {
      seDisablePreviousButton(true);
    } else {
      seDisablePreviousButton(false);
    }
  }, [selectedIndex, upcomingAppts, pastAppts, isPastAppointment]);

  useEffect(() => {
    if (isPatientSelected) {
      UnmountParcel(ParcelType.RightHandSideApp);
      retrieveUpcomingAppointments(EXCLUDE_CANCELLED_FILTER);
      retrievePastAppointments(EXCLUDE_CANCELLED_FILTER);
    }
  }, [isPatientSelected, personGuid]);

  useEffect(() => {
    if (isRefreshButtonClicked) {
      retrieveUpcomingAppointments(EXCLUDE_CANCELLED_FILTER);
      retrievePastAppointments(EXCLUDE_CANCELLED_FILTER);
      setIsRefreshButtonClicked(false);
      setPageNumber(DEFAULT_PAGE_NUMBER);
    }
  }, [isRefreshButtonClicked, personGuid]);

  useEffect(() => {
    const upcomingSelectedKey = Array.from(selectedUpcomingAppointments)[0];
    const pastSelectedKey = Array.from(selectedPastAppointments)[0];

    if (!previousAppointmentState && isPastAppointment && upcomingSelectedKey !== "") {
      setSelectedUpcomingAppointments(new Set([""]));
    }

    if (previousAppointmentState && !isPastAppointment && pastSelectedKey !== "") {
      setSelectedPastAppointments(new Set([""]));
    }
  }, [isPastAppointment]);

  useEffect(() => {
    if (isBannerLoaded) handleCloseDetailsPanel(false);
  }, [isBannerLoaded]);

  const triggerNextPage = () => {
    setPageNumber(pageNumber + 1);
    retrievePastAppointments(
      excludeCancelled ? EXCLUDE_CANCELLED_FILTER : undefined,
      true,
      pageNumber + 1,
      pastAppts,
      pastIncluded
    );
  };

  const onClickBookAppointment = async (_event: any): Promise<void> => {
    try {
      await MountParcel(ParcelType.RightHandSideApp, {
        absoluteUrls: {
          dev: "https://appointments-app.dev.emishealthsolutions.com/emisgroup-book-appointment.js",
          int: "https://appointments-app.int.emishealthsolutions.com/emisgroup-book-appointment.js",
          stg: "https://appointments-app.stg.emis-x.uk/emisgroup-book-appointment.js",
          prd: "https://appointments-app.emis-x.uk/emisgroup-book-appointment.js"
        },
        props: {
          acp: true
        },
        name: "Book Appointment"
      });
      onBookAppointmentOpen(_event);
    } catch (err) {
      console.warn("Error opening Book Appointment: " + SerializeError(err));
    }
  };

  const onBookAppointmentOpen = useCallback((e: any) => {
    const parcelStatus = GetParcelStatus(ParcelType.RightHandSideApp);
    setIsBookAppointmentAppOpen(IsBookAppointmentAppOpen(parcelStatus));
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setDisplayWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);
  const isMobileScreen = displayWidth <= 575;

  let content: JSX.Element[] | JSX.Element;
  if (!isPatientSelected) {
    content = <PatientNotSelected />;
  } else if (isBannerLoaded || isLoadingUpcomingAppts || isLoadingPastAppts) {
    content = <Loading />;
  } else if (upcomingError && pastError) {
    content = <ErrorState setIsTryAgainClick={setIsRefreshButtonClicked} />;
  } else if (allApptsEmptyAndNoError) {
    content = <EmptyState data-testid="empty page" />;
  } else {
    content = (
      <>
        <div className={style.appointmentsContainer}>
          <div className={style.filters}>
            <Checkbox
              checked={excludeCancelled}
              data-testid="exclude-cancelled-checkbox"
              name="Exclude cancelled"
              onChange={onChangeExcludeCancelledFilter}
            >
              {translate("Appointments.ExcludeCancelled")}
            </Checkbox>
          </div>
          <div className={style.contentWrapper} ref={appointmentsContainerRef}>
            <div className={style.container}>
              <div className={style.listWrapper}>
                <AppointmentsList
                  label={translate("Appointments.UpcomingLabel")}
                  appointmentsData={upcomingAppts}
                  relationships={upcomingRelationshipsData}
                  openDetailsPanel={handleOpenDetailsPanel}
                  isOpenDetailsPanel={isOpenDetailsPanel}
                  selectedAppointmentId={selectedAppointmentId}
                  hasUserAppointmentsWritePermision={hasUserAppointmentsWritePermision}
                  isError={upcomingError}
                  errorFrom="upcoming"
                  totalCount={upcomingApptsCount}
                  updateAppointmentAttributes={handleUpdateAppointmentAttributes}
                  handleUpdateStatus={handleUpdateStatus}
                  statusList={statusList}
                  selectedAppointment={selectedUpcomingAppointments}
                  setSelectedAppointment={setSelectedUpcomingAppointments}
                  showConfirmationMessage={handleUpdateAppointment}
                  isUndoClicked={isUndoClicked}
                  setIsUndoClicked={setIsUndoClicked}
                  undoAppointmentId={undoAppointmentId}
                  setShowConfirmationWithoutAction={setShowConfirmationWithoutAction}
                  setCloseConfirmation={setShowConfirmation}
                  handleClickTryAgain={setIsRefreshButtonClicked}
                  isBookAppointmentAppOpen={isBookAppointmentAppOpen}
                />
              </div>
              <div className={style.listWrapper}>
                <AppointmentsList
                  label={translate("Appointments.PastLabel")}
                  appointmentsData={pastAppts}
                  relationships={pastRelationshipsData}
                  openDetailsPanel={handleOpenDetailsPanel}
                  isOpenDetailsPanel={isOpenDetailsPanel}
                  havePastAppointments
                  selectedAppointmentId={selectedAppointmentId}
                  hasUserAppointmentsWritePermision={hasUserAppointmentsWritePermision}
                  isError={pastError}
                  errorFrom="past"
                  totalCount={pastApptsCount}
                  updateAppointmentAttributes={handleUpdateAppointmentAttributes}
                  handleUpdateStatus={handleUpdateStatus}
                  statusList={statusList}
                  selectedAppointment={selectedPastAppointments}
                  setSelectedAppointment={setSelectedPastAppointments}
                  showConfirmationMessage={handleUpdateAppointment}
                  isUndoClicked={isUndoClicked}
                  setIsUndoClicked={setIsUndoClicked}
                  undoAppointmentId={undoAppointmentId}
                  setShowConfirmationWithoutAction={setShowConfirmationWithoutAction}
                  setCloseConfirmation={setShowConfirmation}
                  handleClickTryAgain={setIsRefreshButtonClicked}
                  triggerNextPage={triggerNextPage}
                  hasNextPage={hasNextPage}
                  isBookAppointmentAppOpen={isBookAppointmentAppOpen}
                />
              </div>
            </div>
            {isOpenDetailsPanel && (
              <div className={style.detailsPanelContainer}>
                <AppointmentsDetailsPanel
                  closeDetailsPanel={handleCloseDetailsPanel}
                  appointmentId={selectedAppointmentId}
                  isRightButtonDisabled={disableNextButton}
                  isLeftButtonDisabled={disablePreviousButton}
                  goToNextAppointment={handleGoToNextItem}
                  goToPreviousAppointment={handleGoToPreviousItem}
                  statusUpdated={updateStatus}
                  reasonUpdated={reasonUpdated}
                  notesUpdated={notesUpdated}
                  appointmentIdUpdated={appointmentIdUpdated}
                  hideNavigationButtons={hideDetailsPanelNavigationButtons}
                />
              </div>
            )}
          </div>
        </div>
        <>
          {showConfirmation && (
            <Confirmation
              key={confirmationText}
              confirmationType={confirmationText}
              isConfirmationOpen={showConfirmation}
              isConfirmationSuccess={isConfirmationSuccess}
              isStatusUpdated={statusUpdated}
              undoAppointmentId={appointmentIdUpdated}
              closeConfirmation={handleCloseConfirmation}
              handleClickUndoButton={handleUndoAppointment}
              showConfirmationWithoutAction={showConfirmationWithoutAction}
            />
          )}
        </>
      </>
    );
  }

  return (
    <div className={style.generalStyle} data-testid="appointments-list-wrapper">
      <div className={style.headerText}>
        <div className={style.topBarTitleContainer}>
          <label className={style.topBarTitleText}>
            {isMobileScreen
              ? translate("Appointments.AppointmentsHeaderMobileScreen")
              : translate("Appointments.AppointmentsHeader")}
          </label>
          <RefreshButton
            isPatientSelected={isPatientSelected}
            isBannerLoaded={isBannerLoaded}
            isMobileScreen={isMobileScreen}
            setIsRefreshButtonClicked={setIsRefreshButtonClicked}
          />
        </div>
        {xgpAppointmentsUiShowbookappointment && isPatientSelected && !isBannerLoaded && (
          <Button
            size="medium"
            variant="filled"
            className={style.bookButton}
            data-testid="appointments-booking-button"
            onClick={onClickBookAppointment}
            disabled={isSplitterOpen || !hasUserAppointmentsWritePermision}
          >
            {translate("Appointments.BookAppointment")}
          </Button>
        )}
      </div>
      {content}
    </div>
  );
};

export default Appointments;