// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csJpAgTT6UpisxBXOwgu{padding:var(--spacing);align-self:stretch;border-radius:var(--border-radius);border-bottom:1px solid var(--neutral-90-border-bright, #dde5e9)}.ND5BM10eg2oBYa59f7gu{display:flex;flex-direction:column;justify-content:center;align-items:flex-start}.YYKWTJ5oy4rbEEHv6Jec{margin-top:var(--spacing-large);width:100%;max-height:75vh;height:100%;min-height:30vh;overflow-x:hidden;border:1px solid #dde5e9;border-radius:var(--border-radius-small)}@media only screen and (max-height: 1000px){.YYKWTJ5oy4rbEEHv6Jec{max-height:65vh}}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,kBAAA,CACA,kCAAA,CACA,gEAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CAGF,sBACE,+BAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,wBAAA,CACA,wCAAA,CAGF,4CACE,sBACE,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": `csJpAgTT6UpisxBXOwgu`,
	"contentSkeleton": `ND5BM10eg2oBYa59f7gu`,
	"container": `YYKWTJ5oy4rbEEHv6Jec`
};
export default ___CSS_LOADER_EXPORT___;
