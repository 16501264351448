import { UserAvatar } from "@emisgroup/ui-avatar";

import { ClinicalUserResource } from "../../../types/appointments";
import style from "./UserDetails.module.scss";

interface Props {
  user: ClinicalUserResource;
  color?: string;
  isSmall: boolean;
}

const UserDetails = ({ user, color, isSmall }: Props) => {
  return (
    <div className={style.clinicianDetails}>
      <UserAvatar
        firstName={user?.attributes?.forename}
        lastName={user?.attributes?.surname}
        fullName={user?.meta?.displayName}
        size={"small"}
        className={style.avatarSmall}
        data-testid="clinician-avatar"
        color={color}
      />
      <span className={isSmall ? style.clinicianNameSmall : style.clinicianName} data-testid="clinician-name">
        {user?.meta?.displayName}
      </span>
    </div>
  );
};

export default UserDetails;
