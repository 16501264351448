import { useState, useLayoutEffect } from "react";

const useMobileView = (screenWidth: number) => {
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      setDisplayWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const isMobileView = displayWidth <= screenWidth;

  return { displayWidth, isMobileView };
};

export default useMobileView;
