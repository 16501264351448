// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xjj8ZhCGWm9jFRHSSJ1D{background-color:var(--accent-2-99);border-radius:50%}`, "",{"version":3,"sources":["webpack://./src/components/AppointmentIcon/AppointmentIcon.module.scss"],"names":[],"mappings":"AAAC,sBACC,mCAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"computerIc": `Xjj8ZhCGWm9jFRHSSJ1D`
};
export default ___CSS_LOADER_EXPORT___;
