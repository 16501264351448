import { useState } from "react";

const useConfirmationActions = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConfirmationSuccess, setIsConfirmationSuccess] = useState(true);
  const [isUndoClicked, setIsUndoClicked] = useState(false);
  const [undoAppointmentId, setUndoAppointmentId] = useState("");

  const handleCloseConfirmation = (isClosed: boolean) => {
    setShowConfirmation(isClosed);
  };

  const handleUndoAppointment = (isUndo: boolean, undoAppointmentId: string) => {
    setIsUndoClicked(isUndo);
    setUndoAppointmentId(undoAppointmentId);
  };

  return {
    handleCloseConfirmation,
    handleUndoAppointment,
    showConfirmation,
    setShowConfirmation,
    setIsConfirmationSuccess,
    isConfirmationSuccess,
    isUndoClicked,
    setIsUndoClicked,
    undoAppointmentId,
    setUndoAppointmentId
  };
};

export default useConfirmationActions;
