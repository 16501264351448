// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dAaAYUrv2qh4LVrTzSeO{height:40px;background-color:var(--background-dim);padding:var(--spacing-small) var(--spacing);display:flex;align-items:center;font-size:var(--font-size);line-height:var(--font-size);position:sticky;top:0;z-index:1}.EoossWaGjsWC2nPFRtWd>li{padding:0;margin-bottom:0}.a1uK0dR3UuJqoLrliVYQ{margin:0 0 0 var(--spacing-small)}.mhmHe3YQUhHIh5__nhXB.mhmHe3YQUhHIh5__nhXB.mhmHe3YQUhHIh5__nhXB{margin:0px;top:0;border:2px solid var(--white);line-height:var(--font-size-small)}.atFvkRyCNliPtJ8NnMA3{display:flex;flex-direction:column;justify-content:flex-end;color:var(--neutral-40-text-light, #596165);font-size:var(--font-size-small);font-style:normal;font-weight:var(--font-weight);line-height:20px;padding:var(--spacing)}.c1xW2QM0KZIU3Ynz7hPo.c1xW2QM0KZIU3Ynz7hPo{border-bottom:none}`, "",{"version":3,"sources":["webpack://./src/components/AppointmentsList/AppointmentsList.module.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,sCAAA,CACA,2CAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,4BAAA,CACA,eAAA,CACA,KAAA,CACA,SAAA,CAGF,yBACE,SAAA,CACA,eAAA,CAGF,sBACE,iCAAA,CAGF,gEACE,UCJO,CDKP,KAAA,CACA,6BAAA,CACA,kCAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,2CAAA,CAEA,gCAAA,CACA,iBAAA,CACA,8BAAA,CACA,gBAAA,CACA,sBAAA,CAGF,2CACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listGroupHeader": `dAaAYUrv2qh4LVrTzSeO`,
	"list-box-container": `EoossWaGjsWC2nPFRtWd`,
	"avatarContainer": `a1uK0dR3UuJqoLrliVYQ`,
	"avatar": `mhmHe3YQUhHIh5__nhXB`,
	"emptyUpcoming": `atFvkRyCNliPtJ8NnMA3`,
	"overrideBottomBorder": `c1xW2QM0KZIU3Ynz7hPo`
};
export default ___CSS_LOADER_EXPORT___;
