import { getUserContext } from "@emisgroup/acp-utility-user-context";
import axios, { AxiosInstance } from "axios";
import { toast } from "react-hot-toast";

export const BASE_PATH = process.env.APP_APPOINTMENTS_API_URL ?? "http://localhost:8080/api";
export const SWAGGER_API_KEY = process.env.SWAGGER_API_KEY;

const userContext = getUserContext();

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BASE_PATH,
    headers: {
      Accept: "application/vnd.api+json"
    }
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = await userContext.getAccessToken();

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (config) => config,
    (error) => {
      const { response } = error;
      const data = response?.data;

      if (!data) {
        toast.error("An Error occured.");
        return Promise.reject(error);
      }

      toast.error(`${data.statusCode}: ${data.Error}`);
      return Promise.reject(error);
    }
  );
  return instance;
};

const axiosInstance = createAxiosInstance();
export default axiosInstance;
