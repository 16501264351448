// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js!../../../../node_modules/@emisgroup/ui-styles/dist/base.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.USGDHbTMjOkGCjq_4s5S.USGDHbTMjOkGCjq_4s5S{margin-top:var(--spacing-large);padding-right:0}.USGDHbTMjOkGCjq_4s5S._LAFyMRJ_nkc7K0Hiw4H{display:flex;align-items:center;justify-content:space-between}.USGDHbTMjOkGCjq_4s5S._LAFyMRJ_nkc7K0Hiw4H p{color:var(--text);margin-bottom:0}.s38RCBFD5hHxdWRauQMe.s38RCBFD5hHxdWRauQMe.s38RCBFD5hHxdWRauQMe li>div>p{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/components/common/DialogForm/DialogForm.module.scss"],"names":[],"mappings":"AAGA,2CACE,+BAAA,CACA,eAAA,CAEA,2CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,6CACE,iBAAA,CACA,eAAA,CAMJ,yEACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-section": `USGDHbTMjOkGCjq_4s5S`,
	"form-section__combo-item": `_LAFyMRJ_nkc7K0Hiw4H`,
	"reasonsItems": `s38RCBFD5hHxdWRauQMe`
};
export default ___CSS_LOADER_EXPORT___;
