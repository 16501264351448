// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js!../../../../node_modules/@emisgroup/ui-styles/dist/base.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GsCaefU_micERf4mGZuE.GsCaefU_micERf4mGZuE{border-top:var(--spacing-small) solid var(--cautionary-70);max-width:500px}.yCnuhURF8XojsXGPp7Y0{padding-bottom:var(--spacing-small)}.c_XIa9pqSuhXYgVrBR7D{margin-right:var(--spacing-small)}.imXY9EtfiVIN_909wye7{color:var(--Neutral-20---text, #2b3337);font-size:23px;font-style:normal;font-weight:700;line-height:32px}.g871Uo52ELNFltOkqZjb{margin-top:var(--spacing);margin-bottom:var(--spacing-large);color:#000;font:var(--font-family);line-height:var(--line-height);font-weight:400;font-size:var(--font-size);font-style:normal}.EX_cOHWnCuwbdG4YENyr.EX_cOHWnCuwbdG4YENyr{display:flex;justify-content:flex-end}.zCTQPEcsAd2f66VSVSa9:hover,.zCTQPEcsAd2f66VSVSa9:active{color:--primary-dim}`, "",{"version":3,"sources":["webpack://./src/components/common/SaveChangesDialog/SaveChangesDialog.module.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAIA,2CACE,0DAAA,CACA,eAAA,CAGF,sBACE,mCAAA,CAGF,sBACE,iCAAA,CAGF,sBACE,uCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,yBAAA,CACA,kCAAA,CACA,UCvBM,CDwBN,uBAAA,CACA,8BAAA,CACA,eAAA,CACA,0BAAA,CACA,iBAAA,CAGF,2CACE,YAAA,CACA,wBAAA,CAGF,yDACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog-wrapper": `GsCaefU_micERf4mGZuE`,
	"header": `yCnuhURF8XojsXGPp7Y0`,
	"warningIcon": `c_XIa9pqSuhXYgVrBR7D`,
	"headerTitle": `imXY9EtfiVIN_909wye7`,
	"confirmationMessage": `g871Uo52ELNFltOkqZjb`,
	"action-buttons": `EX_cOHWnCuwbdG4YENyr`,
	"button": `zCTQPEcsAd2f66VSVSa9`
};
export default ___CSS_LOADER_EXPORT___;
