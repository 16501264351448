import { getPatientContext, IPatientContext } from "@emisgroup/acp-utility-patient-context";
import { getUserContext, IUserContext } from "@emisgroup/acp-utility-user-context";
import { IApplicationContext } from "../models/IApplicationContext";
import { PatientBannerState } from "@emisgroup/acp-utility-common/dist/types/patient/patient-banner/types";

/**
 * Class to populate the ApplicationContext with either a mocked
 * or production state.
 */
export class PatientUserContext {
  private readonly usingMockedAuth: boolean;

  private readonly defaultPatientMock = {
    PatientBanner: {
      forename: "Veronica",
      gender: "F",
      isConfidential: false,
      surname: "Thompson",
      title: "Miss",
      patientDetails: {
        onlineServiceEnabled: false
      }
    },
    patientBannerLoaded: true,
    patientBannerState: PatientBannerState.Loaded,
    patientDemographics: undefined,
    patientGuid: "ern:emis:subject:2",
    patientIdentifier: "ern:emis:subject:1",
    personGuid: "ern:emis:subject:1",
    refreshBanner: undefined,
    subscribeToPatientBannerState: undefined,
    subscribeToPatientSwap: undefined,
    subscribeToPatientUpdate: undefined,
    subscribeToPersonSwap: undefined,
    subscribeToRefreshBanner: undefined,
    updateDeferredState: undefined,
    updateFlagToShowPatientBannerLoaded: undefined,
    updateFlagToShowPatientRecordUpdated: () => {},
    updatePatientBanner: undefined,
    updatePatientBannerState: undefined,
    updatePatientIdentifier: undefined,
    updatePatientIdentifiers: undefined,
    updatePersonIdentifier: undefined,
    updatesDeferred: false
  };

  private readonly defaultUserMock = {
    getAccessToken: () => Promise.resolve(localStorage.getItem("accessToken") ?? "accessToken"),
    getUserClaims: () =>
      Promise.resolve({
        orgErn: localStorage.getItem("orgErn") ?? "ern:emis:org:org:d5753427-76ec-48e8-ab19-7f3308d5ca3d", // this Org has LD flags for dev env
        userName: "demo-user",
        email: "demo-user@demo.com",
        givenName: "demo",
        familyName: "user",
        roleName: "demo",
        orgName: "demo",
        odsCode: "demo",
        sub: "demo",
        authorizations: [],
        isEmailVerified: "True"
      }),
    isAuthenticated: true
  };

  /**
   * Constructor.
   *
   * @param mockAuth should we return mocked application context.
   */
  constructor(mockAuth: boolean) {
    this.usingMockedAuth = mockAuth;
  }

  /**
   * Function to populate our patient context.
   *
   * @returns the patient context object of our application context.
   */
  public getPatientContext(): IPatientContext {
    return this.usingMockedAuth ? this.defaultPatientMock : getPatientContext();
  }

  /**
   * Function to populate our user context.
   *
   * @returns the user context object of our application context.
   */
  public getUserContext(): IUserContext {
    return this.usingMockedAuth ? (this.defaultUserMock as IUserContext) : getUserContext();
  }

  /**
   * Function to populate our application context.
   *
   * @returns the application context.
   */
  public getApplicationContext(devMock: boolean): IApplicationContext {
    const user = this.getUserContext();
    const patient = this.getPatientContext();
    if (!patient) return;
    return {
      patient: {
        id: patient.personGuid,
        patientBannerState: patient.patientBannerState,
        isConfidential: patient.patientBanner?.isConfidential
      },
      user,
      devMock: devMock,
      updateByRefreshButtonOnly: false
    };
  }
}
