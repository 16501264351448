import { useTranslation } from "@emisgroup/application-intl";

export const useAppointmentUrgent = (isUrgent: boolean) => {
  const { t: translate } = useTranslation();

  return isUrgent ? translate("Appointments.Yes") : translate("Appointments.No");
};

export const isLastItemInList = (
  isPastAppointment: boolean,
  selectedIndex: number,
  pastList: any[],
  upcomingList: any[]
) => {
  if (isPastAppointment && selectedIndex === pastList?.length - 1) {
    return true;
  } else if (!isPastAppointment && selectedIndex === upcomingList?.length - 1) {
    return true;
  } else {
    return false;
  }
};
