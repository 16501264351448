import { Skeleton } from "@emisgroup/ui-skeleton";
import style from "./Loading.module.scss";
import { useEffect, useState } from "react";

const Loading = () => {
  const [componentCount, setComponentCount] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      const newComponentCount = Math.ceil(screenHeight / 120);
      setComponentCount(newComponentCount);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={style.container}>
      {Array.from({ length: componentCount }, (_, index) => (
        <div className={style.contentSkeleton} key={index}>
          <Skeleton lines={3} variant="listitem" className={style.skeleton} data-testid="skeleton" />
        </div>
      ))}
    </div>
  );
};

export default Loading;
