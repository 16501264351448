import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from "@emisgroup/ui-dropdown-menu";
import { FC, useEffect, useRef, useState } from "react";
import Undo from "~icons/ic/baseline-undo";
import ThreeDots from "~icons/ic/outline-more-vert";
import { statusMappings } from "../../utils/constants";
import style from "./AppointmentsDropdownMenu.module.scss";

import { useFlags } from "@emisgroup/acp-utility-feature-flags";
import { getUpdateStatusPemissions } from "../../utils/updateStatusPermissions";

interface AppointmentsDropdownMenuProps {
  disableDropdown: boolean;
  isUndoClicked: boolean;
  hideViewDetails: boolean;
  nextStatuses: string[];
  cancelStatus: string;
  previousStatus: string;
  appointmentId: string;
  hideAmendAppointmentOption: boolean;
  hideCancelAppointmentOption: boolean;
  undoAppointmentId: string;
  hasUserAppointmentsWritePermision: boolean;
  appointmentStartDate: string;
  handleClickListItem: () => void;
  updatedStatus: (status: string, appointmentId: string) => void;
  showAmendAppointDialog: (showDialog: boolean) => void;
  showCancelAppointmentDialog: (showCancelDialog: boolean) => void;
  setIsUndoClicked: (isUndo: boolean) => void;
  setShowConfirmationWithoutAction: (showConfirmationWithoutAction: boolean) => void;
  setCloseConfirmation: (isClose: boolean) => void;
}

const AppointmentsDropdownMenu: FC<AppointmentsDropdownMenuProps> = ({
  disableDropdown,
  hideViewDetails,
  nextStatuses,
  cancelStatus,
  previousStatus,
  appointmentId,
  hideAmendAppointmentOption,
  hideCancelAppointmentOption,
  hasUserAppointmentsWritePermision,
  isUndoClicked,
  undoAppointmentId,
  appointmentStartDate,
  handleClickListItem,
  updatedStatus,
  showAmendAppointDialog,
  showCancelAppointmentDialog,
  setIsUndoClicked,
  setShowConfirmationWithoutAction,
  setCloseConfirmation
}) => {
  const { t: translate } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hideUpdateStatusTitle, setHideUpdateStatusTitle] = useState(false);
  const dropdownRef = useRef(null);

  const { xgpAppointmentsUiEnableupdateappointment, xgpAppointmentsUiEnablecancelappointment } = useFlags();
  const hasUpdateStatusPermissions = getUpdateStatusPemissions(appointmentStartDate);

  const handleUpdateStatus = (status: string) => {
    const mappedStatus = statusMappings[status] || status;

    updatedStatus(mappedStatus, appointmentId);
    setShowConfirmationWithoutAction(false);
    setCloseConfirmation(false);
  };

  useEffect(() => {
    if (isUndoClicked && undoAppointmentId === appointmentId) {
      handleUpdateStatus(previousStatus);

      setIsUndoClicked(false);
      setShowConfirmationWithoutAction(true);
    }
  }, [isUndoClicked, previousStatus]);

  useEffect(() => {
    setHideUpdateStatusTitle(!hideAmendAppointmentOption || nextStatuses?.length !== 0);
  }, [isDropdownOpen]);

  return (
    <DropdownMenu modal={false} onOpenChange={(open: boolean) => setIsDropdownOpen(open)}>
      <DropdownMenuTrigger>
        <div ref={dropdownRef}>
          <Button
            variant="filled-inverted"
            className={disableDropdown || isDropdownOpen ? style.dropwown : style.dropwownHidde}
            size="medium"
            data-testid="dropdown-menu-button"
          >
            <ThreeDots size="large" title={!isDropdownOpen && "Open"} />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent data-testid="dropdown-menu-content">
        {xgpAppointmentsUiEnableupdateappointment && (
          <DropdownMenuItem
            hidden={!hasUserAppointmentsWritePermision || hideAmendAppointmentOption}
            onSelect={() => showAmendAppointDialog(true)}
            data-testid="amend-appointment-button"
          >
            <div className={style.dropdownItemTitle}>{translate("Appointments.AmendAppointment")}</div>
          </DropdownMenuItem>
        )}
        {xgpAppointmentsUiEnablecancelappointment && (
          <DropdownMenuItem
            hidden={!hasUserAppointmentsWritePermision || hideCancelAppointmentOption}
            onSelect={() => showCancelAppointmentDialog(true)}
            data-testid="cancel-appointment-button"
          >
            <div className={style.dropdownItemTitle}>{translate("Appointments.CancelAppointment")}</div>
          </DropdownMenuItem>
        )}
        {
          <DropdownMenuItem
            onSelect={handleClickListItem}
            data-testid="dropdown-item-view-details"
            disabled={hideViewDetails}
          >
            <div className={style.dropdownItemTitle}>{translate("Appointments.ViewDetails")}</div>
          </DropdownMenuItem>
        }
        {xgpAppointmentsUiEnableupdateappointment &&
          hasUserAppointmentsWritePermision &&
          hideUpdateStatusTitle &&
          hasUpdateStatusPermissions && (
            <DropdownMenuLabel className={style.dropdownLabel} data-testid="dropdown-status-label">
              {translate("Appointments.UpdateStatus")}
            </DropdownMenuLabel>
          )}
        {nextStatuses?.map((status) => (
          <DropdownMenuItem
            key={status}
            onSelect={() => handleUpdateStatus(status)}
            data-testid="dropdown-item-update-status"
            hidden={!hasUserAppointmentsWritePermision || !hasUpdateStatusPermissions}
          >
            <div className={style.statusItem}>{status}</div>
          </DropdownMenuItem>
        ))}
        {cancelStatus && (
          <DropdownMenuItem
            key={cancelStatus}
            onSelect={() => handleUpdateStatus(cancelStatus)}
            data-testid="dropdown-item-cancel-status"
            hidden={!hasUserAppointmentsWritePermision || !hasUpdateStatusPermissions}
          >
            <div className={style.cancelStatusItems}>
              <Undo title="cancelStatus" size={"small"} id={cancelStatus} />
              <div className={style.statusItem}>{translate("Appointments.CancelStatusPrefix") + cancelStatus}</div>
            </div>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AppointmentsDropdownMenu;
