import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { useTranslation } from "@emisgroup/application-intl";

import { useFlags } from "@emisgroup/acp-utility-feature-flags";
import "@emisgroup/ui-styles/dist/base.css";
import classNames from "classnames";
import { updateAppointment } from "../../services/appointments";
import { getStatusesList } from "../../services/dataMapper";
import {
  AppointmentIncludedRelationships,
  AppointmentResource,
  JobCategoryResource,
  StatusResource
} from "../../types/appointments";
import { AppointmentStatus, AppointmentStatusType } from "../../types/status";
import { LOCAL_PATIENT_ID } from "../../utils/constants";
import { getDateFormat, getTimeFormat } from "../../utils/dateFormatter";
import useApplicationContext from "../../utils/hooks/useApplicationContext";
import useMobileView from "../../utils/hooks/useMobileView";
import AmendAppointmentDialog from "../AmendDialog/AmendDialog";
import AppointmentIcon from "../AppointmentIcon/AppointmentIcon";
import AppointmentTag from "../AppointmentTag/AppointmentTag";
import AppointmentsDropdownMenu from "../AppointmentsDropdownMenu/AppointmentsDropdownMenu";
import CancelAppointmentDialog from "../CancelDialog/CancelDialog";
import UserDetails from "../common/UserDetails/UserDetails";
import style from "./AppointmentsListItem.module.scss";

interface IAppointmentProps {
  appointmentData: AppointmentResource;
  filteredData: AppointmentIncludedRelationships;
  selectedItemIndex: number;
  isPastAppointments?: boolean;
  isOpenDetailsPanel?: boolean;
  selectedAppointmentId: string;
  isUndoClicked: boolean;
  undoAppointmentId: string;
  hasUserAppointmentsWritePermision?: boolean;
  statusList: StatusResource[];
  handleUpdateStatus?: (status: string, appointmentId: string) => void;
  updateAppointmentAttributes?: (appointmentId: string, reason: string, notes: string) => void;
  openDetailsPanel: (open: boolean, appointment: string, index: number, isPastAppointment: boolean) => void;
  showConfirmationMessage: (
    isShowConfirmation: boolean,
    confirmationText: string,
    setIsConfirmationSuccess?: boolean,
    isStatusUpdated?: boolean
  ) => void;
  setIsUndoClicked: (isUndo: boolean) => void;
  setShowConfirmationWithoutAction: (showConfirmationWithoutAction: boolean) => void;
  setCloseConfirmation: (isClose: boolean) => void;
  isBookAppointmentAppOpen?: boolean;
}

const LARGE_SCREEN_WIDTH = 1290;
const MEDIUM_SCREEN_WIDTH = 868;
const MEDIUM_SCREEN_WIDTH_WITH_DETAILS = 1521;
const AppointmentsListItem: FC<IAppointmentProps> = (props: IAppointmentProps) => {
  const {
    appointmentData,
    filteredData,
    selectedItemIndex,
    isPastAppointments,
    isOpenDetailsPanel,
    selectedAppointmentId,
    isUndoClicked,
    undoAppointmentId,
    statusList,
    hasUserAppointmentsWritePermision,
    handleUpdateStatus,
    updateAppointmentAttributes,
    openDetailsPanel,
    showConfirmationMessage,
    setIsUndoClicked,
    setShowConfirmationWithoutAction,
    setCloseConfirmation,
    isBookAppointmentAppOpen
  } = props;
  const { t: translate } = useTranslation();
  const clinicianRef = useRef<HTMLDivElement>();
  const { isMobileView, displayWidth } = useMobileView(MEDIUM_SCREEN_WIDTH);
  const [isOpened, setIsOpened] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [activeAppointmentId, setActiveAppointmentId] = useState<string>();
  const [showAmmendAppointmentDialog, setShowAmmendAppointmentDialog] = useState(false);
  const [showCancelAppointmentDialog, setShowCancelAppointmentDialog] = useState(false);
  const [updateStatus, setUpdateStatus] = useState<string>(appointmentData.attributes.status);
  const [statusLate, setStatusLate] = useState(false);
  const [previousStatus, setPreviousStatus] = useState<string>(appointmentData.relationships.previousStatus?.data.id);
  const [filteredStatuses, setFilteredStatuses] = useState<{ nextStatuses: string[]; cancelStatus: string }>();
  const { xgpAppointmentsUiShowdropdownmenu } = useFlags();
  const { personGuid } = useApplicationContext();

  const isHideRoomSection = false;

  const isLargeDisplayWidth = displayWidth > MEDIUM_SCREEN_WIDTH && displayWidth <= LARGE_SCREEN_WIDTH;
  const isMobileScreen = displayWidth <= MEDIUM_SCREEN_WIDTH;
  const isMobileScreenWithDetails = displayWidth <= MEDIUM_SCREEN_WIDTH_WITH_DETAILS && isOpenDetailsPanel;
  const isLargeDisplayWidthOpenedDetails = isOpened && isLargeDisplayWidth;

  const updateAppointmentStatus = useCallback(
    async (status: string, appointmentId: string) => {
      try {
        const result = await updateAppointment(
          process.env.TARGET_ENVIRONMENT === "local" ? LOCAL_PATIENT_ID : personGuid,
          appointmentId,
          appointmentData.attributes.reason,
          appointmentData.attributes.notes,
          status
        );

        setUpdateStatus(result?.data.attributes.status);
        handleUpdateStatus(result?.data.attributes.status, appointmentId);
        showConfirmationMessage(true, translate("Appointments.StatusUpdated"), true, true);
        setPreviousStatus(result.data.relationships.previousStatus.data.id);
      } catch (error) {
        showConfirmationMessage(true, translate("Appointments.StatusUpdateError"), false, false);
        console.error(`Catched Error in appointment list: ${error}`);
      }
    },
    [appointmentData]
  );

  const handleClickListItem = () => {
    openDetailsPanel(true, appointmentData.id, selectedItemIndex, isPastAppointments);
    if (selectedAppointmentId !== activeAppointmentId) {
      setDisplayDropdown(false);
    }
    setDisplayDropdown(true);
    setIsHovered(false);
  };

  const getStyleIfOpenedDetailsPanel = (isLargeScreenOpenedDetails: boolean, isMobileView: boolean) => {
    let userDetailsStyle = null;

    if (!isMobileView) {
      userDetailsStyle = style.userDetails;
    }

    return isLargeScreenOpenedDetails ? style.userDetailsOpenDetails : userDetailsStyle;
  };

  let openedDetailsPanel = getStyleIfOpenedDetailsPanel(isLargeDisplayWidthOpenedDetails, isMobileScreen);

  const hideViewDetails = calculateHideViewDetails();
  const hideDisplayDropdown = calculateHideDisplayDropdown();
  const isStatusCancelled = updateStatus === AppointmentStatus.Cancelled;
  const hideCancelAppointmentOption = isStatusCancelled || updateStatus !== AppointmentStatus.SlotAvailable;

  function calculateHideViewDetails() {
    return isOpenDetailsPanel && appointmentData?.id === selectedAppointmentId;
  }

  function calculateHideDisplayDropdown() {
    return hideViewDetails && displayDropdown;
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = (id: string): void => {
    setActiveAppointmentId((prevId) => (id === prevId || id !== selectedAppointmentId ? undefined : id));
  };

  const handleLateTag = () => {
    const appointmentTime = new Date(appointmentData.attributes.startDateTime);
    const currentTime = new Date();

    const slotTypes: AppointmentStatusType[] = filteredData.slotTypes
      .filter((type) => type.appointmentId === appointmentData.id)
      .map((type) => type.attributes?.statusType);

    const hasFaceToFaceSurgerySlotType = slotTypes.includes(AppointmentStatusType.FaceToFaceSurgery);

    if (
      appointmentTime < currentTime &&
      hasFaceToFaceSurgerySlotType &&
      updateStatus === AppointmentStatus.SlotAvailable
    ) {
      setStatusLate(true);
    } else {
      setStatusLate(false);
    }
  };

  const cancelledStatusTag = (tag: string) => {
    setUpdateStatus(tag);
  };

  const handleStatusTag = useCallback(
    (tag: string) => {
      switch (tag) {
        case AppointmentStatus.SendIn:
        case AppointmentStatus.QuietSendIn:
        case AppointmentStatus.StartCall:
        case AppointmentStatus.VideoStartCall:
          return "In consultation";
        case AppointmentStatus.DNA:
        case AppointmentStatus.TelephoneNotIn:
        case AppointmentStatus.VideoNoAnswer:
          return "DNA";
        default:
          return tag;
      }
    },
    [updateStatus]
  );

  const updateAmendStatus = (status: string, appointmentId: string) => {
    setUpdateStatus(status);
    handleUpdateStatus(status, appointmentId);
  };

  useEffect(() => {
    const slotType = filteredData.slotTypes.find((slot) => slot.appointmentId === appointmentData.id);

    const newFilteredStatuses = getStatusesList(statusList, slotType?.attributes?.statusType, updateStatus);

    setFilteredStatuses(newFilteredStatuses);

    handleLateTag();
  }, [updateStatus, statusList]);

  useLayoutEffect(() => {
    setIsOpened(isOpenDetailsPanel);
  }, [isOpenDetailsPanel]);

  return (
    <div
      key={appointmentData.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleClick(appointmentData.id)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleClick(appointmentData.id);
        }
      }}
      data-testid="dropdown-menu"
      className={style["list-item-container"]}
    >
      <button
        onClick={handleClickListItem}
        className={classNames([
          style["list-item-button"],
          "no padding",
          {
            [style["list-item-button--details-opened"]]: isLargeDisplayWidthOpenedDetails,
            [style["list-item-button--details-closed"]]: !isLargeDisplayWidthOpenedDetails
          }
        ])}
        data-testid="list-item-button"
      >
        <div className={style.appointmentDetailsContainer} data-testid="open-details-panel-button">
          <div
            className={classNames([
              {
                [style.durationModeAndTimeSectionOpenDetails]: isLargeDisplayWidthOpenedDetails,
                [style.durationModeAndTimeSection]: !isLargeDisplayWidthOpenedDetails
              }
            ])}
          >
            <div
              key={appointmentData.id}
              className={classNames([
                {
                  [style.modeAndTimeSectionOpenDetails]: isLargeDisplayWidthOpenedDetails,
                  [style.modeAndTimeSection]: !isLargeDisplayWidthOpenedDetails && appointmentData.meta.timed,
                  [style.modeAndTimeSectionUntimedAppt]: !isLargeDisplayWidthOpenedDetails && !appointmentData.meta.timed
                }
              ])}
            >
              {filteredData?.slotTypes
                .filter((type) => type.appointmentId === appointmentData.id)
                .map((type) => (
                  <div className={style["icon-container"]} key={type.id} data-testid="list-item-slot-type">
                    <AppointmentIcon key={type.appointmentId} appointmentMode={type.attributes?.statusType} />
                  </div>
                ))}
              <div className={style.dateTimeSection} data-testid="list-item-type-date&time">
                <div className={style.appointmentDate}>{getDateFormat(appointmentData.attributes?.startDateTime)}</div>
                {appointmentData.meta.timed &&
                  <div className={style.time}>{getTimeFormat(appointmentData.attributes?.startDateTime)}</div>}
              </div>
            </div>
            {appointmentData.meta.timed && <div
              className={classNames([
                {
                  [style.durationsOpenDetails]: isLargeDisplayWidthOpenedDetails,
                  [style.durations]: !isLargeDisplayWidthOpenedDetails
                }
              ])}
              data-testid="list-item-type-duration"
            >
              {Math.round(appointmentData.meta?.duration) + " " + translate("Appointments.Minutes")}
            </div>
            }
          </div>
          <div
            key={appointmentData.id}
            className={classNames([
              {
                [style.detailsSectionOpenDetails]: isLargeDisplayWidthOpenedDetails,
                [style.detailsSection]: !isLargeDisplayWidthOpenedDetails
              }
            ])}
          >
            <div className={style["user-details-mobile-view"]}>
              <div
                key={appointmentData.id}
                className={classNames([
                  {
                    [style.clinicianDetailsCategoryOpenDetails]: isLargeDisplayWidthOpenedDetails,
                    [style.clinicianDetailsCategory]: !isLargeDisplayWidthOpenedDetails
                  }
                ])}
              >
                <div className={style["user-details-container"]}>
                  {filteredData?.clinicians
                    .filter((clinician) => clinician.appointmentId === appointmentData.id)
                    .map((clinician) => (
                      <div className={openedDetailsPanel} key={clinician.id}>
                        <div ref={clinicianRef}>
                          <UserDetails
                            user={clinician}
                            color={clinician.meta?.displayName}
                            isSmall={
                              isMobileView ||
                              isLargeDisplayWidthOpenedDetails ||
                              isMobileScreenWithDetails ||
                              isBookAppointmentAppOpen
                            }
                          />
                        </div>
                        <div
                          className={
                            isLargeDisplayWidthOpenedDetails
                              ? style.jobCategoryOpenDetails
                              : classNames([
                                style.jobCategory,
                                {
                                  [style.jobCategoryMobile]:
                                    isMobileView || isMobileScreenWithDetails || isBookAppointmentAppOpen,
                                  [style.jobCategoryDesktop]:
                                    !isMobileView || !isMobileScreenWithDetails || !isBookAppointmentAppOpen
                                }
                              ])
                          }
                          data-testid="clinician-job-category-property"
                        >
                          {
                            filteredData?.jobCategory.find(
                              (jobCategory: JobCategoryResource) => jobCategory.clinicianId === clinician.id
                            )?.attributes?.name
                          }
                        </div>
                      </div>
                    ))}
                </div>
                {/* Room filed should be hidden for now (&& filteredData.organisation?.attributes?.name && face2Face) {filteredData?.organisation.attributes?.name}*/}
                {isHideRoomSection && (
                  <div
                    className={classNames([
                      {
                        [style.appointmentRoomOpenDetails]: isLargeDisplayWidthOpenedDetails,
                        [style.appointmentRoom]: !isLargeDisplayWidthOpenedDetails
                      }
                    ])}
                    data-testid="list-item-type-room"
                  ></div>
                )}
              </div>
            </div>
            {isPastAppointments ? (
              <>
                <div className={style.reason} data-testid="list-item-type-reason">
                  {appointmentData.attributes?.reason}
                </div>
                <div data-testid="list-item-type-note"></div>
              </>
            ) : (
              <>
                {appointmentData.attributes?.reason && (
                  <div className={style.reason} data-testid="list-item-type-reason">
                    {appointmentData.attributes?.reason}
                  </div>
                )}
                <div className={style.appointmentNote} data-testid="list-item-type-note">
                  {appointmentData.attributes?.notes}
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={classNames([
            {
              [style.tagSectionOpenDetails]: isLargeDisplayWidthOpenedDetails,
              [style.tagSection]: !isLargeDisplayWidthOpenedDetails
            }
          ])}
          data-testid="list-item-type-tag"
        >
          {appointmentData.attributes?.urgent && !isPastAppointments && (
            <div className={style.urgent}>{translate("Appointments.UrgentTag")}</div>
          )}
          {statusLate ? (
            <div data-testid="late-tag">
              <AppointmentTag status={AppointmentStatus.Late} />
            </div>
          ) : (
            <div data-testid="tag">
              <AppointmentTag status={handleStatusTag(updateStatus)} />
            </div>
          )}
        </div>
      </button>
      {xgpAppointmentsUiShowdropdownmenu && (
        <AppointmentsDropdownMenu
          key={appointmentData.id}
          appointmentId={appointmentData.id}
          handleClickListItem={handleClickListItem}
          disableDropdown={hideDisplayDropdown || isHovered}
          hideViewDetails={hideViewDetails}
          hideAmendAppointmentOption={isStatusCancelled}
          hideCancelAppointmentOption={hideCancelAppointmentOption}
          showAmendAppointDialog={setShowAmmendAppointmentDialog}
          showCancelAppointmentDialog={setShowCancelAppointmentDialog}
          previousStatus={previousStatus}
          nextStatuses={filteredStatuses?.nextStatuses}
          cancelStatus={filteredStatuses?.cancelStatus}
          updatedStatus={updateAppointmentStatus}
          isUndoClicked={isUndoClicked}
          setIsUndoClicked={setIsUndoClicked}
          undoAppointmentId={undoAppointmentId}
          setShowConfirmationWithoutAction={setShowConfirmationWithoutAction}
          setCloseConfirmation={setCloseConfirmation}
          hasUserAppointmentsWritePermision={hasUserAppointmentsWritePermision}
          appointmentStartDate={appointmentData.attributes.startDateTime}
        />
      )}
      {showAmmendAppointmentDialog && (
        <AmendAppointmentDialog
          appointmentId={appointmentData.id}
          nextStatuses={statusList}
          showDialog={showAmmendAppointmentDialog}
          hideCancelAppointmentOption={hideCancelAppointmentOption}
          closeAmendDialog={() => setShowAmmendAppointmentDialog(false)}
          updateAppointmentAttributes={updateAppointmentAttributes}
          updateAmendStatus={updateAmendStatus}
          showConfirmationMessage={showConfirmationMessage}
          openCancelAppointmentDialog={() => setShowCancelAppointmentDialog(true)}
        />
      )}
      {showCancelAppointmentDialog && (
        <CancelAppointmentDialog
          appointmentId={appointmentData.id}
          showDialog={showCancelAppointmentDialog}
          closeDialog={() => setShowCancelAppointmentDialog(false)}
          showConfirmationMessage={showConfirmationMessage}
          updateAppointmentTag={cancelledStatusTag}
        />
      )}
    </div>
  );
};

export default AppointmentsListItem;