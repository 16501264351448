import { Form, FormElement, FormSection } from "@emisgroup/ui-form";

import styles from "./DialogForm.module.scss";
import { Combobox } from "@emisgroup/ui-combobox";
import { ReasonResource } from "../../../types/reasons";
import { REASON_CHARACTERS_LIMIT } from "../../../utils/constants";
import { Key } from "react";

interface Props {
  formLabel: string;
  comboboxLabel: string;
  comboboxPlaceholder: string;
  hasReasonError: boolean;
  inputValue?: string;
  reasonErrorMessage: string;
  reasons: ReasonResource[];
  isLoading: boolean;
  errorText: string | string[];
  selectedReason?: ReasonResource;
  noteFormElementChildren?: React.ReactNode;
  statusFormElementChildren?: React.ReactNode;
  needToCancelChildren?: React.ReactNode;
  formButtonsChildren: React.ReactNode;
  onInputValueChange?: (reason: string) => void;
  setSelectedReason?: (reason: ReasonResource) => void;
  onBlur?: (fieldName: string, fieldValidityState: ValidityState) => void;
}

const DialogForm = ({
  formLabel,
  comboboxLabel,
  comboboxPlaceholder,
  hasReasonError,
  inputValue,
  reasons,
  isLoading,
  errorText,
  selectedReason,
  noteFormElementChildren,
  statusFormElementChildren,
  needToCancelChildren,
  formButtonsChildren,
  reasonErrorMessage,
  onInputValueChange,
  setSelectedReason,
  onBlur
}: Props) => {
  return (
    <Form onBlur={(fieldName: string, fieldValidityState: ValidityState) => onBlur(fieldName, fieldValidityState)}>
      <FormSection className={styles["form-section"]}>
        <FormElement
          className="no-margin"
          fieldId="reason"
          labelText={formLabel}
          mandatory={true}
          errorText={errorText}
        >
          <Combobox
            aria-label={comboboxLabel}
            aria-labelledby="combobox123"
            data-testid="reason-picker-input"
            id="reason-picker"
            inputValue={inputValue}
            onInputChange={(value) => {
              if (value.length <= REASON_CHARACTERS_LIMIT) onInputValueChange(value);
            }}
            placeholder={comboboxPlaceholder}
            invalid={errorText !== ""}
            isDisabled={isLoading}
            required={true}
            menuTrigger="focus"
            hasError={hasReasonError}
            allowsCustomValue
            listClassName={styles.reasonsItems}
            defaultSelectedKey={selectedReason?.id}
            onSelectionChange={(key: Key) => {
              const newSelectedReason = reasons.find((obj) => {
                return obj.id === key;
              })!;
              if (newSelectedReason) {
                setSelectedReason(newSelectedReason);
                onInputValueChange(newSelectedReason.attributes.description);
              }
            }}
            selectedKey={selectedReason?.id}
          >
            {hasReasonError ? (
              <Combobox.Item textValue={reasonErrorMessage}>
                <Combobox.Error>
                  <span>{reasonErrorMessage}</span>
                </Combobox.Error>
              </Combobox.Item>
            ) : (
              reasons?.map((reason: ReasonResource) => (
                <Combobox.Item key={reason.id} textValue={reason.attributes?.description}>
                  <div className={styles["amend-form-section__combo-item"]}>
                    <p>{reason.attributes?.description}</p>
                  </div>
                </Combobox.Item>
              ))
            )}
          </Combobox>
        </FormElement>
        {noteFormElementChildren}
        {statusFormElementChildren}
        {needToCancelChildren}
        {formButtonsChildren}
      </FormSection>
    </Form>
  );
};

export default DialogForm;
