import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import Spanner from "~icons/ic/baseline-build";
import style from "./ErrorPage.module.scss";

/**
 * Responsible for rendering the Error Message component
 *
 * @returns ErrorPage component
 */
const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className={style.error}>
      <div className={style.errorheader} data-testid="errorpage-header">
        <Spanner className={style.icon} title=""></Spanner>
        {t("ErrorPage.Header")}
      </div>
      <div className={style.errorinstructions} data-testid="errorpage-instructions">
        {t("ErrorPage.Instructions")}
      </div>
    </div>
  );
};

export default ErrorPage;
